<template>
    <div class="page">
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="标签分组">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="报告筛选">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                                <el-option label="无" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="-" label-width="0px">
                            <el-date-picker style="width: 200px" v-model="search.time" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="-" label-width="0px">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain @click="getData">查询</el-button>
                            <el-button type="" plain @click="getData">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" height="calc(100vh - 400px)">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                        width="55" />
                    <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                        :width="v.width" :formatter="v.formatter" show-overflow-tooltip :key="idx">
                    </el-table-column>
                    <el-table-column align="center" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)" v-has="'itp_info'">查看详情
                            </el-button>
                            <el-button type="text" size="mini" @click="handleDel(scope.row)" style="color: red"
                                v-has="'itp_del'">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
        <data-form :visible="dialogVisible" @close="dialogVisible = false" :readOnly="true" :localForm="form" @update="getData"
            :idList="idList"></data-form>
    </div>
</template>

<script>
import data from '../../data'
import DataForm from '../../../hi/widgets/dataForm'

export default {
    name: 'phr',
    components: {
        DataForm
    },
    data() {
        return {
            loading: false,
            search: { type: '' },
            table: data.plantable,
            dialogVisible: false,
            form:{},
            idList:[]
        }
    },
    methods: {
        async handleRowChange(row) {
            var that = this
            that.form = row
            const resp = await this.$http.get(`/hi/view/${row.id}`)
            if (!resp.data.data) {
                that.$message.error(resp.data.msg)
            } else {
                let plans = resp.data.data.plans || []
                for (let i = 0; i < plans.length; i++) {
                    if (plans[i].interveneModus == "3") {
                        plans[i].content = JSON.parse(plans[i].content)
                    }
                }
                that.form = resp.data.data
                that.form.plans = plans
                that.dialogVisible = true
            }
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async handleDel(row) {
            this.$confirm(`确认要删除 ${row.name} 的记录吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.$http.delete(`/hi/${row.id}`)
                    .then(resp => {
                        this.$message[resp.data.status](resp.data.msg)
                        if (resp.data.status == 'success') this.getData()
                    }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                this.loading = false
            }).catch(() => {
            });
        },
        async getData() {
            this.loading = true
            let pk = this.$route.params.id
            const resp = await this.$http.get(`/hi/list/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}
</style>
