<template>
    <el-dialog title="干预方案" :visible="visible" width="50%" @close="close" top="1vh">
        <el-row>
            <el-form ref="form" :model="form" size="small" label-position="top">
                <el-form-item label="方案名称" prop="name" :rules="{ required: true, message: '名称', trigger: 'blur' }">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="选择模板">
                    <el-select
                            :disabled="(form.status==1 || !form.status)?false:true"
                            @change="handelModel"
                            style="width: 100%"
                            v-model="form.programId"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入关键词"
                            :remote-method="remoteMethod"
                            :loading="loading">
                        <el-option
                                v-for="item in modelList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="方案介绍" name="1">
                        <div style="height: 380px; overflow-y: auto;">
                            <div>
                                <el-row :gutter="10">
                                    <el-col :span="12">
                                        <el-form-item label="方案有效期">
                                            <el-input-number v-model="form.validTimeValue" :min="1" label=""
                                                             size="small"
                                                             style="width:100%"></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="单位">
                                            <el-select v-model="form.validTimeUnit" placeholder="" size="small"
                                                       style="width:100%">
                                                <el-option label="天" value="d"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item label="方案介绍">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容"
                                              v-model="form.introduction">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="添加图片">
                                    <el-upload action="/api/upload" list-type="picture-card" :headers="uploadHeader"
                                               :file-list="form.image"
                                               :on-success="handleSuccess">
                                        <i slot="default" class="el-icon-plus"></i>
                                        <div slot="file" slot-scope="{file}">
                                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                            <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview"
                                                      @click="handlePictureCardPreview(file)">
                                                    <i class="el-icon-zoom-in"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete"
                                                      @click="handleRemove(file)">
                                                   <i class="el-icon-delete"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible" append-to-body>
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </el-dialog>
                                </el-form-item>
                                <el-form-item label="添加视频">
                                    <el-upload
                                            action="/api/upload"
                                            list-type="picture-card"
                                            :file-list="form.video"
                                            :headers="uploadHeader"
                                            :on-success="handleSuccessVideo"
                                            accept="video/*"
                                            multiple
                                    >
                                        <i slot="default" class="el-icon-plus"></i>
                                        <div slot="file" slot-scope="{file}">
                                            <div class="el-upload-list__item">
                                                <video :src="file.url" class="el-upload-list__item-video" controls></video>
                                                <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview" @click="handleVideoPreview(file)">
                                                  <i class="el-icon-view"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete" @click="handleRemoveVideo(file)">
                                                  <i class="el-icon-delete"></i>
                                                </span>
                                              </span>
                                            </div>
                                        </div>
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="健康指导" name="2">
                        <div style="height: 400px; overflow-y: auto;">
                            <div style="text-align: center">
                                <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;" size="small">
                                    <el-radio-button label="1">膳食方案</el-radio-button>
                                    <el-radio-button label="2">运动方案</el-radio-button>
                                    <el-radio-button label="3">生活建议</el-radio-button>
                                    <el-radio-button label="4">心理指导</el-radio-button>
                                    <el-radio-button label="5">用药建议</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div v-if="tabPosition=='1'">
                                <el-collapse accordion v-model="collapseName">
                                    <el-collapse-item :name="index"
                                                      v-for="(item,index) in form.healthGuidance ? form.healthGuidance.foods : []"
                                                      :key="index">
                                        <template slot="title">
                                            <span style="font-weight: 700">{{index+1}}  {{item.name}}</span>
                                        </template>
                                        <div style="padding: 10px 0"> 方案名称：{{item.name}}
                                            <div style="float: right">
                                                <el-button type="danger" size="mini"
                                                           @click="handelDelgss(index,'foods')">删除方案
                                                </el-button>
                                                <el-button type="warning" size="mini"
                                                           @click="handelXgss(item,index,'foods')">
                                                    编辑方案
                                                </el-button>
                                            </div>
                                        </div>
                                        <div>膳食建议：{{item.content}}</div>
                                    </el-collapse-item>
                                </el-collapse>
                                <div class="add">
                                    <el-button type="primary" size="small" style="width: 100%" plain
                                               @click="handelAddss('foods')">添加膳食方案
                                    </el-button>
                                </div>
                            </div>
                            <div v-if="tabPosition=='2'">
                                <el-collapse accordion v-model="collapseName">
                                    <el-collapse-item :name="index"
                                                      v-for="(item,index) in form.healthGuidance ? form.healthGuidance.sports : []"
                                                      :key="index">
                                        <template slot="title">
                                            <span style="font-weight: 700">{{index+1}}  {{item.name}}</span>
                                        </template>
                                        <div style="padding: 10px 0"> 方案名称：{{item.name}}
                                            <div style="float: right">
                                                <el-button type="danger" size="mini"
                                                           @click="handelDelgss(index,'sports')">删除方案
                                                </el-button>
                                                <el-button type="warning" size="mini"
                                                           @click="handelXgss(item,index,'sports')">
                                                    编辑方案
                                                </el-button>
                                            </div>
                                        </div>
                                        <div>运动建议：{{item.content}}</div>
                                    </el-collapse-item>
                                </el-collapse>
                                <div class="add">
                                    <el-button type="primary" size="small" style="width: 100%" plain
                                               @click="handelAddss('sports')">添加运动方案
                                    </el-button>
                                </div>
                            </div>
                            <div v-if="tabPosition=='3'">
                                <el-form-item label="生活建议">
                                    <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 6, maxRows: 8}"
                                            placeholder="请输入内容"
                                            v-model="form.healthGuidance.life">
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div v-if="tabPosition=='4'">
                                <el-form-item label="心理指导">
                                    <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 6, maxRows: 8}"
                                            placeholder="请输入内容"
                                            v-model="form.healthGuidance.mind">
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div v-if="tabPosition=='5'">
                                <el-form-item label="用药建议">
                                    <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 6, maxRows: 8}"
                                            placeholder="请输入内容"
                                            v-model="form.healthGuidance.drug">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="干预计划" name="3">
                        <div style="height: 400px; overflow-y: auto;">
                            <div style="padding-bottom: 20px">
                                <el-button type="warning" size="small" style="float: right" @click="handelTask">
                                    任务编辑
                                </el-button>
                                <div style="font-weight: 700">计划名称： {{form.planName}}</div>
                            </div>
                            <div>
                                <el-collapse accordion v-model="collapseName">
                                    <el-collapse-item :name="index" v-for="(item,index) in form.plans" :key="index" v-show="item.isdel !== 1">
                                        <template slot="title">
                                            <div style="width: 100%;">
                                                <div style="color: red;float: right;padding-top: 17px"
                                                     class="el-icon-arrow-righ">{{item.level}}级
                                                </div>
                                                <span style="font-weight: 700">第 {{item.planTimeValue}} {{item.planTimeUnit|planTimeUnitFormatter}} {{item.interveneModus|interveneModusFormatter}}</span>
                                            </div>
                                        </template>
                                        <div>执行时间：
                                            <span>{{item.planTime}}</span>
                                        </div>
                                        <div v-if="item.interveneModus=='8'">问卷模板：{{item.msgTemplateKey |
                                            templateFormatter}}
                                        </div>
                                        <div v-if="item.interveneModus=='3'">
                                            <div>微信模板：{{item.msgTemplateKey|templateFormatter}}</div>
                                            <div>医生姓名：{{item.content.keyword1}}</div>
                                            <div>随访时间：{{item.content.keyword2}}</div>
                                            <div>随访内容：{{item.content.keyword3}}</div>
                                        </div>
                                        <div v-else>干预内容：{{item.content}}</div>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button v-if="!readOnly" @click="formSubmits" type="primary" :loading="loading" :disabled="loading">提 交
            </el-button>
        </span>
        <el-dialog
                width="50%"
                :close-on-click-modal="false"
                top="1vh"
                title="干预计划"
                :visible.sync="innerVisible"
                append-to-body>
            <el-form ref="formtash" :model="gyjhForm" size="small" label-position="left" label-width="110px">
                <el-form-item label="计划名称" prop="name" :rules="{ required: true, message: '名称', trigger: 'blur' }">
                    <el-input v-model.trim="gyjhForm.name"></el-input>
                </el-form-item>
                <el-form-item label="计划开始时间" prop="startTime"
                              :rules="{ required: true, message: '请选择计划开始时间', trigger: 'blur' }">
                    <el-date-picker v-model="gyjhForm.startTime" type="date" placeholder="选择日期"
                                    :disabled="(form.status==1 || !form.status)?false:true"
                                    style="width: 100%" value-format="yyyy-MM-dd" @change="handelTime">
                    </el-date-picker>
                </el-form-item>
                <div style="height: 560px; overflow-y: auto;position: relative">
                    <div v-for="(items, index) in gyjhForm.taskList" :key="index" v-show="items.isdel !== 1">
                        <div>
                            <span style="font-weight: 700">计划{{index+1}}</span>
                            <el-button type="text" icon="el-icon-delete" circle style="float: right;color: red"
                                       size="small" @click="handelDel(index)" v-if="items.status=='1' || !items.status"></el-button>
                        </div>
                        <div style="margin: 20px">
                            <el-row :gutter="20">
                                <el-col :span="9">
                                    <el-form-item :prop="'taskList.' + index + '.planTimeValue'" label="执行时间"
                                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                        <el-input v-model="items.planTimeValue" type="number"
                                                  @change="(newValue) => handelZxTime(newValue, index)"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item :prop="'taskList.' + index + '.planTimeUnit'" label-width="0px"
                                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                        <el-select v-model="items.planTimeUnit" placeholder="" size="small"
                                                   style="width:100%" @change="handelChanges(index)">
                                            <el-option label="天" value="d"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item :prop="'taskList.' + index + '.planTime'" label-width="0px"
                                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                        <el-time-picker style="width: 100%;" v-model="items.planTime"
                                                        @change="handelChanges(index)"
                                                        placeholder="时间" value-format="HH:mm:ss"
                                                        :picker-options="{selectableRange: '00:00:00 - 23:59:59'}">
                                        </el-time-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :prop="'taskList.' + index + '.interveneModus'" label="干预形式"
                                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                        <el-select v-model="items.interveneModus" placeholder="" size="small"
                                                   style="width:100%" @change="handelChange(index)">
                                            <el-option label="电话" value="1"></el-option>
                                            <el-option label="微信" value="3"></el-option>
                                            <!--                                            <el-option label="上门" value="4"></el-option>-->
                                            <!--                                            <el-option label="智能评估" value="5"></el-option>-->
                                            <el-option label="随访问卷" value="8"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :prop="'taskList.' + index + '.interveneCategory'" label="干预类别"
                                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                        <el-select v-model="items.interveneCategory" placeholder="" size="small"
                                                   style="width:100%" @change="handelChanges(index)">
                                            <el-option label="随访" value="1"></el-option>
                                            <el-option label="健康教育" value="2"></el-option>
                                            <el-option label="膳食指导" value="3"></el-option>
                                            <el-option label="运动指导" value="4"></el-option>
                                            <el-option label="生活方式指导" value="5"></el-option>
                                            <el-option label="心理指导" value="6"></el-option>
                                            <el-option label="用药指导" value="7"></el-option>
                                            <el-option label="其它" value="8"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item :prop="'taskList.' + index + '.level'" label="紧急程度"
                                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                        <el-select v-model="items.level" placeholder="" size="small"
                                                   style="width:100%" @change="handelChanges(index)">
                                            <el-option label="1" value="1"></el-option>
                                            <el-option label="2" value="2"></el-option>
                                            <el-option label="3" value="3"></el-option>
                                            <el-option label="4" value="4"></el-option>
                                            <el-option label="5" value="5"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div v-if="items.interveneModus=='3'">
                                <el-form-item label="微信模板">
                                    <el-select v-model="items.msgTemplateKey" placeholder="" size="small"
                                               style="width:100%" @change="handelChanges(index)">
                                        <el-option label="随访提醒" value="HEALTH_FOLLOW_UP_REMINDER_WX_NOTIFY"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="医生姓名">
                                    <el-input v-model="items.content.keyword1" @change="handelChanges(index)"></el-input>
                                </el-form-item>
                                <el-form-item label="随访时间">
                                    <el-date-picker v-model="items.content.keyword2" type="date" placeholder="选择日期"
                                                    style="width: 100%" @change="handelChanges(index)"
                                                    value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="随访内容">
                                    <el-input v-model="items.content.keyword3" :autosize="{ minRows: 2, maxRows: 4}"
                                              type="textarea" @change="handelChanges(index)"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item label="问卷模板" v-if="items.interveneModus=='8'">
                                <el-select v-model="items.msgTemplateKey" placeholder="" size="small"
                                           style="width:100%" @change="handelChanges(index)">
                                    <el-option label="体重随访问卷" value="539c441cf0304940e77cb475904a27b3"></el-option>
                                    <el-option label="血压随访问卷" value="9d77521265cf573ad2d82b76799b31d4"></el-option>
                                    <el-option label="血脂随访问卷" value="f9972b0d684d99bb5024e853e9d09e68"></el-option>
                                    <el-option label="血糖随访问卷" value="a8ee86e21e1d398e03383888c01f6082"></el-option>
                                    <el-option label="尿酸随访问卷" value="39428c3186c601caa51e7eaf12d882c7"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="干预内容" v-if="items.interveneModus=='1' || items.interveneModus=='8'">
                                <el-input v-model="items.content" :autosize="{ minRows: 2, maxRows: 4}" @change="handelChanges(index)"
                                          type="textarea"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div>
                        <el-button type="primary" size="small" style="width: 100%" plain @click="handelAdd">添加计划
                        </el-button>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="innerVisible=false">取 消</el-button>
                <el-button v-if="!readOnly" @click="formSubmit" type="primary" :loading="loading" :disabled="loading">确 认
                </el-button>
            </span>
        </el-dialog>
        <el-dialog
                width="50%"
                :close-on-click-modal="false"
                title="添加/修改方案"
                :visible.sync="innerVisibleEdit"
                append-to-body>
            <el-form ref="faform" :model="faform" size="small" label-position="left" label-width="80px">
                <el-form-item label="方案名称" prop="name" :rules="{ required: true, message: '名称', trigger: 'blur' }">
                    <el-input v-model.trim="faform.name"></el-input>
                </el-form-item>
                <el-form-item label="建议">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 6, maxRows: 8}"
                            placeholder="请输入内容"
                            v-model="faform.content">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="innerVisibleEdit=false">取 消</el-button>
                <el-button v-if="!readOnly" type="primary" :loading="loading" :disabled="loading" @click="handelFangan">提 交
                </el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>
<script>
    export default {
        props: {
            visible: {
                default: false
            },
            localForm: {
                default: {}
            },
            idList: {
                default: []
            },
            readOnly: {
                default: false,
            }
        },
        data() {
            let token = localStorage.getItem('token')
            return {
                modelList: [],
                uploadHeader: {Authorization: `Bearer ${token}`},
                form: {
                    plans: [],
                    healthGuidance: {},
                    image: [],
                    video: [],
                    supplementContent: {}
                },
                gyjhForm: {
                    name: "",
                    startTime: "",
                    taskList: []
                },
                tabPosition: "1",
                activeName: "1",
                loading: false,
                dialogImageUrl: '',
                dialogVisible: false,
                innerVisible: false,
                innerVisibleEdit: false,
                disabled: false,
                collapseName: "1",
                faform: {},
                type: "ssfa"
            }
        },
        filters: {
            planTimeUnitFormatter: function (value) {
                switch (value) {
                    case "d":
                        return "天"
                    default:
                        return status
                }
            },
            templateFormatter: function (value) {
                switch (value) {
                    case "539c441cf0304940e77cb475904a27b3":
                        return "体重随访问卷"
                    case "9d77521265cf573ad2d82b76799b31d4":
                        return "血压随访问卷"
                    case "f9972b0d684d99bb5024e853e9d09e68":
                        return "血脂随访问卷"
                    case "a8ee86e21e1d398e03383888c01f6082":
                        return "血糖随访问卷"
                    case "39428c3186c601caa51e7eaf12d882c7":
                        return "尿酸随访问卷"
                    case "HEALTH_FOLLOW_UP_REMINDER_WX_NOTIFY":
                        return "随访提醒"
                    default:
                        return status
                }
            },
            interveneModusFormatter: function (value) {
                switch (value) {
                    case "1":
                        return "电话"
                    case "3":
                        return "微信"
                    case "4":
                        return "上门"
                    case "5":
                        return "智能评估"
                    case "8":
                        return "随访问卷"
                    default:
                        return status
                }
            },
        },
        watch: {
            localForm: {
                deep: true,
                handler(newValue) {
                    this.form = JSON.parse(JSON.stringify(newValue));
                }
            }
        },
        methods: {
            handleVideoPreview(file) {
                // 处理视频预览的逻辑，可以打开一个对话框显示视频
                // 例如：
                this.$alert(`<video src="${file.url}" controls style="max-width: 100%;"></video>`, '视频预览', {
                    dangerouslyUseHTMLString: true,
                    customClass: 'video-preview-dialog'
                });
            },
            handelChanges(index){
                this.$set(this.gyjhForm.taskList[index], 'isEdit', 1);
            },
            handelTime(v) {
                if (this.gyjhForm.taskList.length) {
                    for (let i = 0; i < this.gyjhForm.taskList.length; i++) {
                        var dateTime = new Date(v);
                        if (this.gyjhForm.taskList[i].planTimeValue) {
                            dateTime = dateTime.setDate(dateTime.getDate() + parseInt(this.gyjhForm.taskList[i].planTimeValue) - 1);
                            dateTime = new Date(dateTime);
                            this.gyjhForm.taskList[i].planExeTime = dateTime
                            if (this.gyjhForm.taskList[i].interveneModus == "3") {
                                this.$set(this.gyjhForm.taskList[i].content, 'keyword2', dateTime);
                            }
                        }
                    }
                }
            },
            handelZxTime(newValue, index) {
                this.$set(this.gyjhForm.taskList[index], 'isEdit', 1);
                if (!newValue) {
                    return
                }
                var dateTime = new Date(this.gyjhForm.startTime);
                dateTime = dateTime.setDate(dateTime.getDate() + parseInt(newValue) - 1);
                dateTime = new Date(dateTime);
                this.gyjhForm.taskList[index].planExeTime = dateTime
                if (this.gyjhForm.taskList[index].interveneModus == "3") {
                    this.$set(this.gyjhForm.taskList[index].content, 'keyword2', dateTime);
                }

            },
            async handelModel() {
                var that = this
                const resp = await this.$http.get(`/program/view/${this.form.programId}`)
                if (!resp.data.data) {
                    that.$message.error(resp.data.msg)
                } else {
                    var taskList = []
                    resp.data.data.plans.forEach(function (item) {
                        if (item.interveneModus == "3") {
                            if (typeof item.content === "string") {
                                item.content = JSON.parse(item.content || "{}")
                            }
                            taskList.push(item);
                        } else {
                            taskList.push(item);
                        }
                    })
                    resp.data.data.plans = taskList
                    that.form = resp.data.data
                    that.form.id = ""
                }
            },
            async remoteMethod(query) {
                if (query !== '') {
                    const encodedQuery = encodeURIComponent(query);
                    const resp = await this.$http.get(`/program?page=1&pageSize=100&name=${encodedQuery}`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.modelList = resp.data.data
                    }
                }
            },
            handelChange(index) {
                var dateTime = new Date(this.gyjhForm.startTime);
                dateTime = dateTime.setDate(dateTime.getDate() + parseInt(this.gyjhForm.taskList[index].planTimeValue) - 1);
                dateTime = new Date().formatDate(new Date(dateTime));
                if (this.gyjhForm.taskList[index].interveneModus == "3") {
                    this.$set(this.gyjhForm.taskList[index], 'content',
                        {
                            "keyword1": "",
                            "keyword2": dateTime,
                            "keyword3": ""
                        });
                } else {
                    this.$set(this.gyjhForm.taskList[index], 'content', "");
                }
                this.$set(this.gyjhForm.taskList[index], 'isEdit', 1);
            },
            handelTask() {
                this.gyjhForm = {
                    name: this.form.planName,
                    startTime: this.form.startTime,
                    taskList: JSON.parse(JSON.stringify(this.form.plans))
                }
                this.innerVisible = true
            },
            handelAddss(type) {
                this.type = type
                this.faform = {}
                this.innerVisibleEdit = true
            },
            handelDelgss(index, type) {
                this.form.healthGuidance[type].splice(index, 1)
            },
            handelXgss(item, index, type) {
                this.type = type
                this.faform = item
                this.faform['index'] = index
                this.innerVisibleEdit = true
            },
            handelFangan() {
                const valid = this.$refs['faform'].validate().then(res => res).catch(() => {
                    return false
                });
                if (!valid) return
                if (!this.form.healthGuidance[this.type]) {
                    this.form.healthGuidance[this.type] = []
                }
                this.form.healthGuidance[this.type].push(this.faform)
                this.innerVisibleEdit = false
            },
            handelAdd() {
                this.$refs.formtash.validate((valid) => {
                    if (valid) {
                        this.gyjhForm.taskList.push({
                            content: "",
                            interveneModus: "1",
                            msgTemplateKey: "",
                            level: "",
                            interveneCategory: "",
                            planTime: "",
                            planTimeUnit: "",
                            planTimeValue: "",
                        })
                    } else {
                        return false;
                    }
                });
            },
            handelDel(index) {
                // this.gyjhForm.taskList.splice(index, 1)
                this.$set(this.gyjhForm.taskList[index], 'isdel', 1);
            },
            handleSuccessVideo(res, file) {
                this.form.video.push({url: file.response.filePath, "uid": file.uid});
            },
            handleSuccess(res, file) {
                this.form.image.push({url: file.response.filePath, "uid": file.uid});
            },
            handleRemoveVideo(file) {
                const index = this.form.video.findIndex(item => item.uid === file.uid);
                if (index !== -1) {
                    this.form.video.splice(index, 1);
                }
            },
            handleRemove(file) {
                const index = this.form.image.findIndex(item => item.uid === file.uid);
                if (index !== -1) {
                    this.form.image.splice(index, 1);
                }
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;

            },
            async formSubmit() {
                var that = this
                this.$refs.formtash.validate((valid) => {
                    if (valid) {
                        // 使用 Vue.set 或者 this.$set 来确保视图能够更新
                        that.$set(that.form, 'plans', JSON.parse(JSON.stringify(that.gyjhForm.taskList)));
                        that.$set(that.form, 'planName', that.gyjhForm.name);
                        that.$set(that.form, 'startTime', that.gyjhForm.startTime);
                        that.innerVisible = false;
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            close() {
                this.$emit("close")
            },
            async formSubmits() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(() => {
                    return false
                });
                if(!this.form.startTime){
                    this.$message.warning("计划时间没有选择")
                    return
                }
                if (!valid) return
                this.loading = true
                let dataForm = JSON.parse(JSON.stringify(this.form))
                dataForm.idList = this.idList
                const resp = await this.$http.post('/hi/form', dataForm)
                if (resp.data.status == 'success') {
                    this.$message[resp.data.status]("提交成功！")
                    this.innerVisible = false
                    this.loading = false
                    this.$emit('close')
                    this.$emit('update')
                }else {
                    this.$message[resp.data.status](resp.data.msg)
                    this.loading = false
                }
            }
        },
        mounted() {

        }
    };
</script>

<style scoped>
    /deep/ .el-input__inner {
        -webkit-appearance: none;
        background: #f7f7f7;
        border: 1px solid #f7f7f7;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 13px;
    }

    /deep/ .el-date-editor .el-range-input {
        background: #f7f7f7;
    }

    /deep/ .el-textarea__inner {
        -webkit-appearance: none;
        background: #f7f7f7;
        border: 1px solid #f7f7f7;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 13px;
    }

    /deep/ el-form-item--small .el-form-item__content, /deep/ .el-form-item--small .el-form-item__label {
        line-height: 25px;
    }

    /deep/ .el-form-item--mini.el-form-item, /deep/ .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }

    /deep/ .el-form--label-top .el-form-item__label {
        float: none;
        display: inline-block;
        text-align: left;
        padding: 0 0 5px;
    }

    /deep/ .el-form-item--small .el-form-item__content, /deep/ .el-form-item--small .el-form-item__label {
        line-height: 25px;
    }

    .add {
        position: absolute;
        bottom: 0px;
        right: 0;
        left: 0;
    }
</style>
