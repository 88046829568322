<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="管理员名称：" prop="nickname">
                <el-input v-model="form.nickname" />
            </el-form-item>
            <el-form-item label="管理员手机号：" prop="phone">
                <el-input v-model="form.phone" />
            </el-form-item>
            <el-form-item label="用户类型：" prop="user_type" >
                <el-select v-model="form.user_type" placeholder="请选择" style="width: 100%" @change="handelUserType">
                    <el-option label="系统管理员" value="1" v-if="$store.getters.userType=='1'"></el-option>
                    <el-option label="企业端+医生端" value="4"></el-option>
                    <el-option label="企业端" value="2"></el-option>
                    <el-option label="医生端" value="3" v-if="$store.getters.userType=='1'"></el-option>
                    <el-option label="值班医生" value="5" v-if="$store.getters.userType=='1'"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属单位：" prop="company" v-if="form.user_type">
                <cascaderCompanyMultiple v-model="form.company" v-if="form.user_type=='3'" @change="handelRole"></cascaderCompanyMultiple>
                <cascaderCompany v-model="form.company" v-else @change="handelRole" user_type="1"></cascaderCompany>
            </el-form-item>
            <el-form-item label="单位分组" prop="wpName">
                <el-select v-model="form.wpName" placeholder="请选择" style="width:100%" clearable >
                    <el-option :label="item.name" :value="item.name" v-for="(item,index) in gzqList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户角色：" prop="role">
                <el-select v-model="form.role" placeholder="请选择" style="width: 100%">
                    <el-option
                            v-for="item in roleList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="登录账号：" prop="username">
                <el-input v-model="form.username" />
            </el-form-item>
            <el-form-item label="用户密码：" prop="password" v-if="!form.id">
                <el-input v-model="form.password" show-password />
            </el-form-item>
            <el-form-item label="启动状态：" prop="status" >
                <el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            gzqList: {
                default: []
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                companyList:[],
                innerVisible: false,
                roleList:[],
                rules: {
                    username: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    nickname: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入原密码', trigger: 'blur'},
                        {min: 5, max: 132, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ],
                    user_type: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ],
                    company: [
                        {required: true, message: '请选择', trigger: 'blur'},
                    ],
                    role: [
                        {required: true, message: '请选择', trigger: 'blur'},
                    ],
                }
            }
        },
        watch: {
            // 使用函数返回你想监听的属性路径
            'form.company': {
                // eslint-disable-next-line no-unused-vars
                handler(newVal, oldVal) {
                    this.handelRole();
                },
                deep: true // 当需要深度监听对象内部变化时启用
            }
        },
        methods: {
            handelUserType(v){
                if(v == "3"){
                    this.form.company = []
                }else {
                    this.form.company = ""
                }
            },
            handelRole() {
                var id = ""
                this.roleList = []
                if(!this.form.company || this.form.company.length == 0){
                    return
                }
                if(this.form.user_type == "3" && this.form.company.length){
                    id = this.form.company[0]
                }else {
                    id =this.form.company
                }
                this.$http.get(`/company/role/company/${id}?page=1&pageSize=500&user_type=${this.form.user_type}`)
                    .then(resp => {
                        this.roleList= resp.data.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
            getDataCompany() {
                this.$http.get(`/company/company/tree`)
                    .then(resp => {
                        this.companyList= resp.data.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    var dataForm = JSON.parse(JSON.stringify(this.form))
                    if (!Array.isArray(dataForm.company)) {
                        dataForm.company = [dataForm.company]
                    }
                    let url = `/auth/user`
                    const resp = await this.$http.post(url, dataForm)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        },
        mounted() {
            // this.getData()
            // this.getDataCompany()
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
