<template>
    <el-dialog :visible="visible" width="50%" @close="handleClose" class="dialog" :title="title" @open="handelOpen">
        <el-row id="printZhiye">
            <div class="title1">大庆油田员工健康包保责任卡</div>
            <div class="title2">包保对象</div>
            <table style="width: 100%; border-collapse: collapse;">
                <tbody>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">姓名</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{form.name}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">单位</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">
                        <span v-if="form.entOrgNameTwo">{{form.entOrgNameTwo}}</span>
                        <span v-if="form.entOrgNameThree">|{{form.entOrgNameThree}}</span>
                        <span v-if="form.entOrgNameFour">|{{form.entOrgNameFour}}</span>
                        <span v-if="form.entOrgNameFive">|{{form.entOrgNameFive}}</span>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">性别</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">
                        <span v-if="form.sex == '2'"> 女 </span>
                        <span v-if="form.sex == '1'"> 男 </span>
                    </td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">岗位/职务</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%" v-if="form.patientInfo">{{form.patientInfo.companyJob}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%" v-else></td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">岗位状况</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">
                        <el-checkbox-group v-model="form.position_status">
                            <el-checkbox label="1" disabled>在岗</el-checkbox>
                            <el-checkbox label="2" disabled>长期离岗</el-checkbox>
                        </el-checkbox-group>
                    </td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">包保级别</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">
                        <el-checkbox-group v-model="form.coverage_level">
                            <el-checkbox label="三级" disabled></el-checkbox>
                            <el-checkbox label="二级" disabled></el-checkbox>
                        </el-checkbox-group>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">异常指标</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 82%" colspan="3">
                        <el-checkbox-group v-model="form.abnormal_lndicator">
                            <el-checkbox label="高血压" disabled></el-checkbox>
                            <el-checkbox label="高血脂" disabled></el-checkbox>
                            <el-checkbox label="高尿酸" disabled></el-checkbox>
                            <el-checkbox label="高体重" disabled></el-checkbox>
                            <el-checkbox label="慢病" disabled></el-checkbox>
                            <el-checkbox label="大病" disabled></el-checkbox>
                        </el-checkbox-group>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">联系电话</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{form.mobile}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">家属或紧急<br>
                        联系人电话</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{form.ecmobile}}</td>
                </tr>
                </tbody>
            </table>
            <div class="title2">包保责任人（A/B岗）</div>
            <table style="width: 100%; border-collapse: collapse;">
                <tbody>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">姓名A</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersname(0,'name')}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">姓名B</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersname(1,'name')}}</td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">单位A</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersCompany(0)}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">单位B</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersCompany(1)}}</td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">岗位/职务A</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersname(0,'companyJob')}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">岗位/职务B</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersname(1,'companyJob')}}</td>
                </tr>
                <tr style="text-align: center;">
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">联系电话A</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersname(0,'mobile')}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 18%">联系电话B</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 32%">{{b_list|filtersname(1,'mobile')}}</td>
                </tr>
                </tbody>
            </table>
            <div style="border-left: 1px solid #333;border-right: 1px solid #333;border-bottom: 1px solid #333; padding: 8px;">
                包保日期： {{b_list|filtersname(0,'created_at') | datetime}}
            </div>
            <div style="border-left: 1px solid #333;border-right: 1px solid #333; padding: 8px;">包保记录：</div>
            <table style="width: 100%; border-collapse: collapse;">
                <tbody>
                <tr style="text-align: center;" v-for="(item,index) in recordList" :key="index">
                    <td style="border: 1px solid #333; padding: 8px;width: 30%">{{item.created_at|datetime}}</td>
                    <td style="border: 1px solid #333; padding: 8px;width: 70%">{{item.record}}</td>
                </tr>
                </tbody>
            </table>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button  type="primary" v-print="print">打印</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "详情"
            },
        },
        data() {
            return {
                print: {
                    id: 'printZhiye',
                },
                recordList:[],
                b_list:[]
            }
        },
        filters: {
            filtersname: function (value, type, field) {
                if (Array.isArray(value) && value.length > type) {
                    if(field == "companyJob"){
                        if(value[type].patientInfo){
                            return value[type].patientInfo.companyJob
                        }
                        return ""
                    }
                    return value[type][field] || "";
                }
                return "";
            },
            filtersCompany: function (value, type) {
                if (Array.isArray(value) && value.length > type) {
                    var entOrgNameTwo = value[type].entOrgNameTwo
                    var entOrgNameThree = value[type].entOrgNameThree
                    var entOrgNameFour = value[type].entOrgNameFour
                    var entOrgNameFive = value[type].entOrgNameFive
                    var company = []
                    if(entOrgNameTwo){
                        company.push(entOrgNameTwo)
                    }
                    if(entOrgNameThree){
                        company.push(entOrgNameThree)
                    }
                    if(entOrgNameFour){
                        company.push(entOrgNameFour)
                    }
                    if(entOrgNameFive){
                        company.push(entOrgNameFive)
                    }
                    return company.join("/")
                }
                return "";
            },
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
            handeRecord(){
                try {
                    this.$http.get(`/company/healthInsurance/${this.form.user_id}`)
                        .then(resp => {
                            this.recordList = resp.data.data
                        }).catch(err => {
                        console.log(err)
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                }
            },
            handeBaobao(){
                try {
                    this.$http.get(`/company/healthInsurance/baobao/${this.form.user_id}`)
                        .then(resp => {
                            this.b_list = resp.data.data
                        }).catch(err => {
                        console.log(err)
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                }
            },
            handelOpen(){
                this.handeBaobao()
                this.handeRecord()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }
    .title1{
        border: 1px solid #333;
        font-size: 20px;
        text-align: center;
        padding: 10px;
    }
    .title2{
        border-right: 1px solid #333;
        border-left: 1px solid #333;
        font-size: 16px;
        text-align: center;
        padding: 10px;
    }
    .info {
        line-height: 30px;
        padding: 10px;
        border-bottom: 1px solid #333;
        border-right: 1px solid #333;
        border-left: 1px solid #333;
        .title {
            font-weight: bold;
        }
    }
    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    /*.title {*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    padding: 10px;*/

    /*    .title-text {*/
    /*        text-align: center;*/
    /*        border-bottom: 1px solid #EBEEF5;*/
    /*        border-top: 1px solid #EBEEF5;*/
    /*        padding: 10px;*/
    /*    }*/
    /*}*/

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
