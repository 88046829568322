<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title" @open="handelOpen">
        <el-form ref="form" :model="form" label-width="150px" size="small" >
            <el-form-item label="上传文件：" >
                <el-upload
                        :headers="headers" :on-success="handleSuccess"
                        :on-error="handleError"
                        :before-upload="beforeUpload"
                        class="upload-demo"
                        ref="upload"
                        drag
                        :limit="1"
                        :auto-upload="false"
                        accept=".xlsx, .xls"
                        action="/api/phr/upload"
                        >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传xlsx、slx文件，且不超过1000条</div>
                </el-upload>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="submitUpload" :disabled="but_disabled" v-loading="but_loading">确 定</el-button>
                <el-button @click="handleClose" :disabled="but_disabled" v-loading="but_loading">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            title: {
                default: "体检报告上传"
            },
        },
        data() {
            return {
                form:{},
                but_loading: false,
                but_disabled: false,
            }
        },
        computed: {
            headers() {
                const token = localStorage.getItem('token')
                return {
                    Authorization: `Bearer ${token}`
                }
            }
        },
        methods: {
            handelOpen() {
                setTimeout(() => {
                    this.but_loading = false;
                    this.but_disabled = false;

                    // 确保 this.$refs.upload 不是 undefined
                    if (this.$refs.upload) {
                        this.$refs.upload.clearFiles();
                    } else {
                        // 如果 this.$refs.upload 是 undefined，可以考虑在此处理或者给出提示
                        console.log("Upload component is not available.");
                    }
                }, 500);
            },
            // eslint-disable-next-line
            beforeUpload(file) {
                if (!file) {
                    this.$message.error('文件不能为空');
                    return false;
                }
                return true;
            },
            // eslint-disable-next-line
            handleSuccess(response, file, fileList) {
                if(response.status == 'success') {
                    this.$emit('close')
                }else {
                    this.but_loading = false
                    this.but_disabled = false
                    this.$message.error(response.msg);
                }
            },
            // eslint-disable-next-line
            handleError(error, file, fileList) {
                this.but_loading = false
                this.but_disabled = false
                console.error("上传失败", error);
            },
            submitUpload() {
                const files = this.$refs.upload.uploadFiles;
                if (files.length === 0) {
                    this.$message.error('文件不能为空');
                    return; // 文件为空则不执行上传操作
                }
                this.but_loading = true
                this.but_disabled = true
                this.$refs.upload.submit();
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
