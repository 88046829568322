<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" content="团体健康评估"/>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="单位分组">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="储运销售公司" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="体检报告">
                            <el-date-picker
                                    style="width: 315px"
                                    v-model="search.time"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="评估状态">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                                <el-option label="已审核" value="5"></el-option>
                                <el-option label="已评估" value="2"></el-option>
                                <el-option label="已暂存" value="3"></el-option>
                                <el-option label="未评估" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div>
            <el-table :data="table.data" border stripe size="mini" v-loading="loading"
                      style="width: 100%;cursor: pointer"
                      height="100%" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center"
                                 :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                 width="55"/>
                <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                                 :width="v.width" :formatter="v.formatter" show-overflow-tooltip :key="idx">
                </el-table-column>
                <el-table-column align="center" width="200" label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="handleInfo(scope.row)">
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <jianKangTiJian :visible="model" @close="model=false" :form="form"/>
        <nvzhigongTiJian :visible="nvmodel" @close="nvmodel=false" :form="form"/>
        <zhuanxiangTiJian :visible="zxmodel" @close="zxmodel=false" :form="form"/>
    </div>
</template>

<script>
    import jianKangTiJian from './export/jianKangTiJian'
    import nvzhigongTiJian from './export/nvzhigongTiJian'
    import zhuanxiangTiJian from './export/zhuanxiangTiJian'

    export default {
        components: {
            jianKangTiJian,
            nvzhigongTiJian,
            zhuanxiangTiJian
        },
        data() {
            return {
                loading: false,
                search: {},
                form: {},
                model: false,
                nvmodel: false,
                zxmodel: false,
                table: {
                    columns: [
                        {title: '类型', field: 'type', width: ''},
                        {title: '名称', field: 'name', width: ''},
                        {title: '成员列表', field: 'users', width: ''},
                        {title: '创建时间', field: 'created', width: ''},
                        {title: '评估医生', field: 'created', width: ''},
                        {title: '审核时间', field: 'doctor', width: ''},
                        {title: '审核医生', field: 'audit_doctor', width: ''},
                    ],
                    total: 0,
                    page: 1,
                    pageSize: 20,
                    data: [{
                        type: '健康体检',
                        name: '2023年度XXX公司团体评估报告',
                        users: '共732人,点击查看详情',
                        created: '2023-11-12 09:30:12',
                        audit_date: '2023-11-13 14:22:43',
                        doctor: '王兵兵',
                        audit_doctor: '唐海燕',
                    }, {
                        type: '职业体检',
                        name: '2023年度XXX公司职业体检评估报告',
                        users: '共732人,点击查看详情',
                        created: '2023-11-12 09:30:12',
                        audit_date: '2023-11-13 14:22:43',
                        doctor: '王兵兵',
                        audit_doctor: '唐海燕',
                    }, {
                        type: '女工体检',
                        name: '2023年度XXX公司女工体检评估报告',
                        users: '共732人,点击查看详情',
                        created: '2023-11-12 09:30:12',
                        audit_date: '2023-11-13 14:22:43',
                        doctor: '王兵兵',
                        audit_doctor: '唐海燕',
                    }]
                }
            }
        },
        methods: {
            handleInfo(row) {
                this.form = row
                if(row.type == "健康体检"){
                    this.model = true
                }
                if(row.type == "女工体检"){
                    this.nvmodel = true
                }
                if(row.type == "职业体检"){
                    this.zxmodel = true
                }
            }
        }
    }
</script>
