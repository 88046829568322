<template>
    <div class="page">
        <div class="header">
            <div class="btns">

                <el-button size="mini" @click="handleDownload" icon="el-icon-download" type="defalut" v-has="'batch_hra3_download'">导出</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康风险评估"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="50px">
                <!--                <el-form-item label="单位分组" label-width="70px">-->
                <!--                    <el-select v-model="search.wpName" placeholder="请选择" style="width:100%" clearable>-->
                <!--                        <el-option label="全部" value="全部"></el-option>-->
                <!--                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in gzqList"-->
                <!--                                   :key="index"></el-option>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="工作单位" label-width="70px">
                    <cascader-company-name v-model="search.companyName"></cascader-company-name>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="search.sex" placeholder="请选择" style="width:150px" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="年龄">
                    <el-select v-model="search.age" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="35岁以下" value="1"></el-option>
                        <el-option label="35岁至44岁" value="2"></el-option>
                        <el-option label="45岁及以上" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索">
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:90px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="激活状态" label-width="70px">
                    <el-select v-model="search.activeStatus" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未激活" value="0"></el-option>
                        <el-option label="已激活" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评估问卷" label-width="70px">
                    <el-select v-model="search.questionnaireStatus" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="已回填" value="3"></el-option>
                        <el-option label="未回填" value="1"></el-option>
                        <el-option label="未发送" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评估状态" label-width="70px">
                    <el-select v-model="search.status" placeholder="请选择" style="width:140px" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="已完成" value="2"></el-option>
                        <el-option label="待审核" value="1"></el-option>
                        <el-option label="未评估" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评估时间" label-width="70px">
                    <el-date-picker
                            type="monthrange"
                            style="width:260px"
                            v-model="search.pgTime"
                            value-format="yyyy-MM"
                            range-separator="至"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="风险人群" label-width="70px">
                    <el-select v-model="search.risklevel" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="健康人群" value="健康人群"></el-option>
                        <el-option label="风险人群" value="风险人群"></el-option>
                        <el-option label="异常人群" value="异常人群"></el-option>
                        <el-option label="慢病人群" value="慢病人群"></el-option>
                        <el-option label="大病人群" value="大病人群"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人群等级" label-width="70px">
                    <el-select v-model="search.level" placeholder="请选择" style="width:100%" filterable allow-create
                               default-first-option clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in tagList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指标等级" label-width="70px">
                    <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable
                                 v-model="search.risklevelList"></el-cascader>
                </el-form-item>
                <el-form-item label="报告筛选" label-width="70px">
                    <el-select v-model="search.reportType" placeholder="请选择" style="width:180px" clearable>
                        <el-option label="体检报告" value="1"></el-option>
                        <el-option label="小屋报告" value="2"></el-option>
                        <el-option label="无体检报告" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="-" label-width="8px">
                    <el-date-picker v-model="search.reportTime" type="daterange" range-separator="至" clearable
                                    style="width: 300px"
                                    :disabled="search.reportType?false:true"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'hra3_search'">查询</el-button>
                    <el-button type="" plain @click="reset" v-has="'hra3_search'">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="search" style="background-color: #fff">
            <div class="btns">
                <el-button type="primary" size="mini" @click="handleSendQuestionnaire"
                           :disabled="idList.length ? false : true" v-has="'hra3_wj'">发送评估问卷
                </el-button>
                <el-button type="primary" size="mini" @click="handleRemind"
                           :disabled="idList.length ? false : true" v-has="'hra3_cb'">批量催办
                </el-button>
            </div>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%" border
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center">
                    </el-table-column>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.sex == '2'"> 女 </span>
                                <span v-if="scope.row.sex == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                            <div>
                                <small> {{ isFullIdCard[scope.$index] ? scope.row.idCard :
                                    maskIdCardRegex(scope.row.idCard) }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                   @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="风险等级标签" width="130">
                        <template slot-scope="scope">
                            <popover-JCXM :tagList="scope.row"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="联系方式" width="200">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                            </div>
                            <div>{{ scope.row.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="激活状态" width="" prop="activeStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.activeStatus" type="success">已激活</el-tag>
                            <el-tag v-else type="info">未激活</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="问卷回填" prop="questionnaire_status" width="150">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.questionnaire_status === '3'" type="success" style="cursor: pointer"
                                    @click="handleRowWjpg(scope.row)" v-has="'hra3_wjpg'">已填写
                            </el-tag>
                            <el-tag v-else-if="scope.row.questionnaire_status === '2'" type="warning">已超时未填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaire_status === '1'" type="warning">已发送未填写</el-tag>
                            <el-tag v-else type="info">未发送</el-tag>
                            <div v-if="scope.row.questionnaire_time">{{scope.row.questionnaire_time|datetime}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="评估状态" width="140" prop="status">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == '2'">已完成</el-tag>
                            <el-tag v-else-if="scope.row.status == '1'">待审核</el-tag>
                            <el-tag v-else>未开始</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="评估方式" width="140" prop="type">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.type == '1'">人工评估</el-tag>
                            <el-tag v-if="scope.row.type == '2'">问卷评估</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="最近评估" prop="evaluation_date" width="140">
                        <template slot-scope="scope">
                            <div>{{scope.row.evaluation_date|datetime}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="评估医生" prop="guardian" width="140">
                    </el-table-column>
                    <el-table-column align="center" label="当前风险等级" prop="current_risk_level" width="140">
                    </el-table-column>
                    <el-table-column align="center" label="最新风险等级" prop="latest_risk_level" width="140">
                    </el-table-column>
                    <el-table-column align="center" width="240" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowInfo(scope.row)" v-has="'hra3_xq'">评估详情</el-button>
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)" v-has="'hra3_da'">健康档案</el-button>
                            <el-button type="text" size="mini" @click="handleRowRgpg(scope.row)"
                                       :disabled="scope.row.status=='2'" v-has="'hra3_rgpg'">人工评估
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <data-form :visible="dialogVisible" :row="form" @close="dialogVisible = false" @update="getData"></data-form>
        <rgpg-form :visible="rgpgVisible" :form="form" @close="rgpgVisible = false" @update="getData"></rgpg-form>
        <pgreport :visible="pgreportVisiable" :form="form" @close="pgreportVisiable = false"/>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <el-dialog
                :visible.sync="dialogVisibleExport"
                title="提示"
                width="30%"
        >
            <p>导出的数据不能超过2万条，请缩小查询范围或导出当前范围内的前2万条数据。</p>
            <p>导出时请不要关闭页面。</p>
            <el-progress :percentage="progress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleExport = false" :disabled="but_disabled" v-loading="but_loading">取消</el-button>
                <el-button type="primary" @click="exportLimitedData" :disabled="but_disabled" v-loading="but_loading">导 出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../../data/formatter"
    import dataForm from "./widgets/dataform";
    import rgpgForm from "./widgets/rgpgform";
    import pgreport from "./widgets/pgreport";
    import {dbList} from "../../../data/utlisData";

    const makeInitSearch = () => {
        return {
            companyName: "",
            activeStatus: "",
            key: "name",
            value: "",
            wpName: "",
            sex: "",
            age: "",
            status: "",
            risklevel: "",
            risklevelList: [],
            level: '',
            questionnaireStatus: "",
            pgTime: []
        }
    }

    const makeInitForm = () => {
        return {
            id: null,
            name: "",
            age: "",
            sex: "",
            idCard: "",
            nation: "",
            marital: "",
            guardian: "",
            ecmobile: "",
            companyName: "",
            workType: "",
            blood: "",
            companyJob: "",
            height: "",
            weight: "",
            sbp: "",
            dbp: "",
            hr: "",
            waist: "",
            tc: "",
            ldl: "",
            hdl: "",
            fbg: "",
            hcy: "",
            ua: "",
            chol: "",
            tg: "",
            operation: [],
            drugIrritability: [],
            foodsIrritability: [],
            foods: [],
            expose: [],
            parents: [],
            heredity: []
        }
    }
    export default {
        name: 'progress2',
        components: {
            dataForm,
            rgpgForm,
            pgreport,
        },
        data() {
            return {
                gzqList: [],
                dbList: dbList,
                defaultProps: {
                    multiple: true,
                    children: 'tags',
                    label: 'name',
                    value: 'value',
                    emitPath: false
                },
                isFullIdCard: {},
                tagList: [
                    {
                        id: 3,
                        name: "三级"
                    },
                    {
                        id: 2,
                        name: "二级"
                    },
                    {
                        id: 1,
                        name: "一级"
                    },
                ],
                form: makeInitForm(),
                rgpgVisible: false,
                dialogVisible: false,
                pgreportVisiable: false,
                formatter: formatter,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                idList: [],
                dialogVisibleExport: false,
                progress: 0, // 进度条的百分比
                but_loading: false,
                but_disabled: false,
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(from.path.indexOf("/main/phr/detail/")>-1){
                    vm.handlePopState()
                }
            });
        },
        methods: {
            async handleDownload() {
                this.progress = 0; // 初始化进度
                this.dialogVisibleExport = true; // 显示进度条对话框
                // 模拟导出数据的过程
            },
            async exportLimitedData() {
                try {
                    this.but_loading = true
                    this.but_disabled = true
                    this.$http({
                        url: `/healthAssessment/hra/export`,
                        method: 'POST',
                        responseType: 'blob', // 重要：确保响应类型为blob
                        data: this.search,
                        headers: {},
                        onDownloadProgress:(progressEvent)=>{
                            let progressBar = Math.round( progressEvent.loaded / progressEvent.total*100);
                            //接收进度为99%的时候需要留一点前端编译的时间
                            if(progressBar >= 99){
                                this.progress = 99;
                            }else{
                                this.progress = progressBar;
                            }
                        }
                    }).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        let fileName = 'default.xlsx';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                            if (fileNameMatch && fileNameMatch.length === 2) {
                                fileName = decodeURIComponent(fileNameMatch[1]);
                            }
                        }
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url); // 清理
                        this.but_loading = false
                        this.but_disabled = false
                        this.dialogVisibleExport = false; // 隐藏导出进度对话框
                        this.$message.success('数据导出完成！');
                    }).catch((error) => {
                        this.but_loading = false
                        this.but_disabled = false
                        this.$message({
                            message: error,
                            type: 'error'
                        });
                    });
                } catch (error) {
                    this.but_loading = false
                    this.but_disabled = false
                    this.$message({
                        message: error,
                        type: 'error'
                    });
                }
            },
            handlePopState() {
                const savedConditions = localStorage.getItem('searchConditions');
                const black = localStorage.getItem('back');
                if (savedConditions && black) {
                    this.search = JSON.parse(savedConditions);
                }
            },
            handleSendQuestionnaire() {
                this.$confirm(`确认要发送评估问卷吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/healthAssessment/hra/send`, {userIdList: this.idList})
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            async getinfo(row) {
                try {
                    this.form = makeInitForm()
                    this.form.id = row.id
                    this.form.current_risk_level = row.latest_risk_level
                    this.form.type = "1"
                    const resp = await this.$http.get(`/phr/view/${row.id}`)
                    if (resp.data) {
                        this.form.name = resp.data.data.patientInfo?.name || ""
                        this.form.age = resp.data.data.patientInfo?.age || row.age || ''
                        this.form.sex = resp.data.data.patientInfo?.sex || ""
                        this.form.idCard = resp.data.data.patientInfo?.idCard || ""
                        this.form.nation = resp.data.data.patientInfo?.nation || ""
                        this.form.marital = resp.data.data.patientInfo?.marital || ""
                        this.form.guardian = resp.data.data.patientInfo?.guardian || ""
                        this.form.ecmobile = resp.data.data.patientInfo?.ecmobile || ""
                        this.form.companyName = resp.data.data.patientInfo?.companyName || ""
                        this.form.companyJob = resp.data.data.patientInfo?.companyJob || ""
                        this.form.workType = resp.data.data.patientInfo?.workType || ""
                        this.form.blood = resp.data.data.healthInfo?.blood || ""
                        this.dialogVisible = true
                    }
                    this.dialogVisible = true
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async handleRowWjpg(row) {
                try {
                    this.form = makeInitForm()
                    const resp = await this.$http.get(`/phr/hra/quote/${row.questionnaire_id}`)
                    if (resp.data) {
                        this.form = resp.data.data.content
                        this.form.wjId = resp.data.data.id
                        this.form.current_risk_level = row.latest_risk_level
                        this.form.id = row.id
                        this.form.type = "2"
                        this.dialogVisible = true
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleRowRgpg(row) {
                this.getinfo(row)
            },
            handleRowInfo(row) {
                this.pgreportVisiable = true
                this.form.id = row.id
            },
            maskIdCardRegex(idCard) {
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handleRemind() {
                this.$confirm(`确认要催办评估问卷吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/healthAssessment/hra/remind`, {userIdList: this.idList})
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                    localStorage.removeItem('searchConditions');
                    localStorage.removeItem('back');
                }, 0);
            },
            handleRowChange(row) {
                this.$router.push({path: `/main/phr/detail/${row.id}`})
            },
            handleSelectionChange(val) {
                var that = this
                that.idList = []
                val.forEach(function (item) {
                    var patientInfo = {
                        "name": item.name,
                        "sex": item.sex,
                        "age": item.age,
                        "mobile": item.mobile,
                        "idCard": item.idCard,
                        "companyName": item.companyName,
                    }
                    that.idList.push({id: item.id, patientInfo: patientInfo,reminder_count:item.reminder_count,activeStatus:item.activeStatus,
                        questionnaire_status:item.questionnaire_status, openId:item.openid,questionnaire_id:item.questionnaire_id,status:item.status,
                        questionnaire_time:item.questionnaire_time})
                })
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/healthAssessment/hra`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                    localStorage.setItem('searchConditions',JSON.stringify(this.search));
                }
                this.loading = false
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            },
        },
        mounted() {
            this.getUserOwn()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
