<template>
    <div class="page" v-loading="loading">
        <div class="header">
            <div style="float:right">
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="goBack" content="健康档案"/>
        </div>
        <el-card class="box-card" style="margin-top: 17px">
            <div class="text-item">
                <div class="header-text">
                    <div><span class="label">姓名：</span><span class="value">{{ form.name }}</span></div>
                    <div><span class="label">性别：</span>
                        <span v-if="form.sex == '2'" class="value"> 女 </span>
                        <span v-if="form.sex == '1'" class="value"> 男 </span>
                    </div>
                    <div><span class="label">年龄：</span><span class="value">{{ form.age }}岁</span></div>
                    <div><span class="label">生日：</span><span class="value">{{ form.patientInfo ? form.patientInfo.birthday : '' }}</span>
                    </div>
                    <div><span class="label">证件号码：</span><span
                            class="value">{{ maskIdCardRegex(form.idCard || "") }}</span></div>
                    <div>
                        <span class="label">单位分组：</span>
                        <span class="value">{{ form.wpName }}
                            <i class="el-icon-success" style="font-size: 16px;color:#67C23A;padding-left: 10px"></i>
                        </span>
                    </div>
                    <div><span class="label">消息发送渠道：</span><span class="value">{{ form.wpName }}</span></div>
                </div>
                <div style="padding-top: 20px">
                    <div>
                        <span class="label">客户标签：</span>
                        <el-button class="button-new-tag" size="mini" @click="showInput">+ 添加标签</el-button>
                        <el-tag type="danger" size="medium" v-for="(item, index) in form.level_tag" :key="index">{{ item
                            }}
                        </el-tag>
                        <el-tag size="medium" v-if="form.audience_tag">{{ form.audience_tag }}</el-tag>
                    </div>
                </div>
            </div>
        </el-card>
        <div>
            <el-tabs v-model="activetab" type="border-card">
                <el-tab-pane label="基础信息" name="BaseInfo">
                    <BaseInfo v-if="activetab == 'BaseInfo'" :form="form"></BaseInfo>
                </el-tab-pane>
                <el-tab-pane label="健康报告" name="HealthReport">
                    <HealthReport v-if="activetab == 'HealthReport'"></HealthReport>
                </el-tab-pane>
                <el-tab-pane label="指标监测" name="Indicator">
                    <Indicator v-if="activetab == 'Indicator'"></Indicator>
                </el-tab-pane>
                <el-tab-pane label="健康评估" name="HealthAssessment">
                    <HealthAssessment v-if="activetab == 'HealthAssessment'"></HealthAssessment>
                </el-tab-pane>
                <el-tab-pane label="心理评估" name="psychologicalAssessment">
                    <psychologicalAssessment v-if="activetab == 'psychologicalAssessment'"></psychologicalAssessment>
                </el-tab-pane>
                <el-tab-pane label="干预方案" name="InterventionPlan">
                    <InterventionPlan> v-if="activetab=='InterventionPlan'"</InterventionPlan>
                </el-tab-pane>
                <el-tab-pane label="干预任务" name="InterventionTasks">
                    <InterventionTasks v-if="activetab == 'InterventionTasks'"></InterventionTasks>
                </el-tab-pane>
                <el-tab-pane label="跟踪随访" name="FollowUp">
                    <FollowUp v-if="activetab == 'FollowUp'"></FollowUp>
                </el-tab-pane>
                <el-tab-pane label="健康小结" name="healthSummary">
                    <healthSummary v-if="activetab == 'healthSummary'"></healthSummary>
                </el-tab-pane>
                <el-tab-pane label="操作记录" name="operationRecords">
                    <operationRecords v-if="activetab == 'operationRecords'"></operationRecords>
                </el-tab-pane>
            </el-tabs>
        </div>
        <data-form :visible="inputVisible" :form="form" @close="inputVisible = false" @update="getData"
                   ref="vmodel"></data-form>
    </div>
</template>

<script>
    import BaseInfo from "./tabs/baseinfo"
    import FollowUp from "./tabs/followUp"
    import HealthAssessment from "./tabs/healthAssessment"
    import HealthReport from "./tabs/healthReport"
    import healthSummary from "./tabs/healthSummary"
    import Indicator from "./tabs/Indicator"
    import InterventionPlan from "./tabs/InterventionPlan"
    import InterventionTasks from "./tabs/InterventionTasks"
    import operationRecords from "./tabs/operationRecords"
    import psychologicalAssessment from "./tabs/psychologicalAssessment"
    import dataForm from "../widgets/dataForm";

    export default {
        name: "view",
        components: {
            BaseInfo,
            FollowUp,
            HealthAssessment,
            HealthReport,
            healthSummary,
            Indicator,
            InterventionPlan,
            InterventionTasks,
            operationRecords,
            psychologicalAssessment,
            dataForm
        },
        data() {
            return {
                loading: false,
                activetab: "BaseInfo",
                form: {},
                inputVisible: false,
                inputValue: '',
                familyHistory: {
                    "fater": {"value": [], "remark": ""}, "monter": {"value": [], "remark": ""},
                    "brothers": {"value": [], "remark": ""}, "children": {"value": [], "remark": ""},
                    "familyDiseases": {"value": [], "remark": ""}
                },
                pastHistory: {
                    "trauma": {"has": null, "list": []},
                    "illness": {"has": null, "list": []},
                    "surgery": {"has": null, "list": []},
                    "transfusion": {"has": null, "list": []}
                },
                other: {
                    "all": "", "frist": "", "expose": [], "heredity": 0,
                    "disability": {"value": [], "remark": ""}, "heredityDetail": "",
                    "illnessHostory": "", "drugIrritability": {"value": [], "remark": ""},
                    "foodsIrritability": ""
                },
                environment: {"water": "", "energy": "", "toilet": "", "acorral": "", "exhaust": ""},
                lifeInfo: {"daily": "", "drink": "", "foods": "", "sleep": "", "smoke": "", "style": ""},
                healthInfo: {"BMI": "", "blood": "", "waist": "", "height": "", "weight": ""}
            }
        },
        methods: {
            goBack() {
                localStorage.setItem('back', true);
                this.$router.go(-1);
            },
            maskIdCardRegex(idCard) {
                if (!idCard) {
                    return ""
                }
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            showInput() {
                this.inputVisible = true;
                // this.$refs.vmodel.getData()
                // this.$nethis.$refsxtTick(_ => {
                //     this.$refs.saveTagInput.$refs.input.focus();
                // });
            },
            async getData() {
                this.inputVisible = false;
                try {
                    this.loading = true
                    let pk = this.$route.params.id
                    if (pk) {
                        const resp = await this.$http.get(`/phr/view/${pk}`)
                        if (resp.data) {
                            if (!resp.data.data.familyHistory) {
                                resp.data.data.familyHistory = this.familyHistory
                            }
                            if (!resp.data.data.pastHistory) {
                                resp.data.data.pastHistory = this.pastHistory
                            }
                            if (!resp.data.data.other) {
                                resp.data.data.other = this.other
                            }
                            if (!resp.data.data.environment) {
                                resp.data.data.environment = this.environment
                            }
                            if (!resp.data.data.lifeInfo) {
                                resp.data.data.lifeInfo = this.lifeInfo
                            }
                            if (!resp.data.data.healthInfo) {
                                resp.data.data.healthInfo = this.healthInfo
                            }
                            this.form = resp.data.data
                            this.loading = false
                        }
                    }
                    this.loading = false
                } catch (e) {
                    this.loading = false
                    this.$message.error(e.message)
                }
            },
            async handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.KHBQ.push(inputValue);
                    try {
                        let url = `/phr/tag`
                        var dataForm = {
                            idList: [this.form.id],
                            KHBQ: this.form.KHBQ
                        }
                        const resp = await this.$http.post(url, dataForm)
                        this.$message[resp.data.status](resp.data.msg)
                    } catch (e) {
                        this.$message.error(e.message)
                    }
                }
                this.inputVisible = false;
                this.inputValue = '';

            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .box-card {
        margin-bottom: 10px;
    }

    .text-item {
        font-size: 14px;

        .header-text {
            display: flex;
        }

        .value {
            padding-right: 10px;
        }
    }

    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-right: 10px;
        height: 25px;
        line-height: 25px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>
