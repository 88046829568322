<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh"
                           class="hidden-sm-only hidden-sm-only">刷 新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康包保管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <!--                <el-form-item label="工作单位" label-width="70px">-->
                <!--                    <cascader-company-name v-model="search.companyName"></cascader-company-name>-->
                <!--                </el-form-item>-->
                <el-form-item label="异常项">
                    <el-select v-model="search.abnormal_lndicator" placeholder="请选择" clearable>
                        <el-option label="高血压" value="高血压"></el-option>
                        <el-option label="高血脂" value="高血脂"></el-option>
                        <el-option label="高血糖" value="高血糖"></el-option>
                        <el-option label="高尿酸" value="高尿酸"></el-option>
                        <el-option label="高体重" value="高体重"></el-option>
                        <el-option label="慢病" value="慢病"></el-option>
                        <el-option label="大病" value="大病"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关怀体检项">
                    <el-select v-model="search.examination_item" placeholder="请选择" clearable>
                        <el-option label="头颈+冠脉CTA颅脑MRA" value="头颈+冠脉CTA颅脑MRA"></el-option>
                        <el-option label="头颈、冠脉二合一CTA" value="头颈、冠脉二合一CTA"></el-option>
                        <el-option label="冠脉CTA" value="冠脉CTA"></el-option>
                        <el-option label="颅脑MRA" value="颅脑MRA"></el-option>
                        <el-option label="冠脉CTA颅脑MRA" value="冠脉CTA颅脑MRA"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="治疗措施">
                    <el-select v-model="search.treatment_method" placeholder="请选择" clearable>
                        <el-option label="药物指导治疗" value="药物指导治疗"></el-option>
                        <el-option label="入院药物治疗" value="入院药物治疗"></el-option>
                        <el-option label="入院手术治疗" value="入院手术治疗"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="医院">
                    <el-select v-model="search.hospital" placeholder="请选择" clearable>
                        <el-option label="油田总院" value="油田总院"></el-option>
                        <el-option label="龙南医院" value="龙南医院"></el-option>
                        <el-option label="人民医院" value="人民医院"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="包保对象">
                    <el-input placeholder="请输入包保对象名称" v-model="search.name" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="包保责任人">
                    <el-input placeholder="请输入包保责任人" v-model="search.zrname" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search" v-has="'hie_search'">查询
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-row :gutter="20" style="height: 100%;display: flex;">
            <el-col :span="4">
                <div style="padding: 10px">
                    <el-input size="mini" placeholder="输入关键字进行过滤" v-model="filterText">
                    </el-input>
                </div>
                <el-tree v-loading="loading" class="filter-tree" highlight-current
                         @node-click="handleNodeClick" :filter-node-method="filterNode" :data="companyList"
                         node-key="id" :default-expanded-keys="[1]"
                         :props="{ checkStrictly: true, label: 'name', value: 'id', emitPath: false }" ref="tree">
                </el-tree>
            </el-col>
            <el-col :span="20" class="page">
                <div class="main-content">
                    <div class="table-content">
                        <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%"
                                  border @expand-change="handleExpandChange">
                            <el-table-column type="expand">
                                <template slot-scope="props">
                                    <el-table :data="recordList" stripe size="small" width="100%" height="300px" border>
                                        <el-table-column type="index" label="序号" align="center" width="55"/>
                                        <el-table-column align="center" label="记录日期" width="">
                                            <template slot-scope="scope">
                                                <div> {{scope.row.created_at|datetime}}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="包保记录" width="" prop="record">
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column type="index" label="序号" align="center"
                                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                             width="55"/>
                            <el-table-column label="包保对象" width="" align="center">
                                <el-table-column align="center" label="单位" width="200px"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                        <span v-if="scope.row.entOrgNameThree">|{{scope.row.entOrgNameThree}}</span>
                                        <span v-if="scope.row.entOrgNameFour">|{{scope.row.entOrgNameFour}}</span>
                                        <span v-if="scope.row.entOrgNameFive">|{{scope.row.entOrgNameFive}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="基本信息" width="160">
                                    <template slot-scope="scope">
                                        <div>
                                            <span>{{ scope.row.name }}</span>
                                            <span v-if="scope.row.sex == '2'"> 女 </span>
                                            <span v-if="scope.row.sex == '1'"> 男 </span>
                                            <span>{{scope.row.companyJob}}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="position_status" label="岗位状态" width="" align="center"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.position_status == '2'"> 长期离岗 </span>
                                        <span v-if="scope.row.position_status == '1'"> 在岗 </span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="mobile" label="联系电话" width="" align="center"
                                                 show-overflow-tooltip/>
                                <el-table-column prop="ecmobile" label="紧急联系人电话" align="center" width="150"
                                                 show-overflow-tooltip/>
                                <el-table-column prop="coverage_level" label="包保级别" align="center" width=""
                                                 show-overflow-tooltip/>
                                <el-table-column prop="abnormal_lndicator" label="异常指标" align="center" width=""
                                                 show-overflow-tooltip/>
                            </el-table-column>
                            <!--                            <el-table-column label="包保责任人" width="" align="center">-->
                            <!--                                <el-table-column label="姓名A" width="" align="center" show-overflow-tooltip>-->
                            <!--                                    <template slot-scope="scope">-->
                            <!--                                        <div>-->
                            <!--                                            {{scope.row.b_list|filtersname(0,'name')}}-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </el-table-column>-->
                            <!--                                <el-table-column label="岗位/职务A" width="150" align="center" show-overflow-tooltip>-->
                            <!--                                    <template slot-scope="scope">-->
                            <!--                                        <div>-->
                            <!--                                            {{scope.row.b_list|filtersname(0,'companyJob')}}-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </el-table-column>-->
                            <!--                                <el-table-column label="联系电话A" align="center" width="150" show-overflow-tooltip>-->
                            <!--                                    <template slot-scope="scope">-->
                            <!--                                        <div>-->
                            <!--                                            {{scope.row.b_list|filtersname(0,'mobile')}}-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </el-table-column>-->
                            <!--                                <el-table-column label="姓名B" width="" align="center" show-overflow-tooltip>-->
                            <!--                                    <template slot-scope="scope">-->
                            <!--                                        <div>-->
                            <!--                                            {{scope.row.b_list|filtersname(1,'name')}}-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </el-table-column>-->
                            <!--                                <el-table-column label="岗位/职务B" width="150" align="center" show-overflow-tooltip>-->
                            <!--                                    <template slot-scope="scope">-->
                            <!--                                        <div>-->
                            <!--                                            {{scope.row.b_list|filtersname(1,'companyJob')}}-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </el-table-column>-->
                            <!--                                <el-table-column label="联系电话B" align="center" width="150" show-overflow-tooltip>-->
                            <!--                                    <template slot-scope="scope">-->
                            <!--                                        <div>-->
                            <!--                                            {{scope.row.b_list|filtersname(1,'mobile')}}-->
                            <!--                                        </div>-->
                            <!--                                    </template>-->
                            <!--                                </el-table-column>-->
                            <!--                            </el-table-column>-->
                            <el-table-column label="精准关怀体检情况" align="center" width="">
                                <el-table-column prop="examination_condition" label="检前状况描述" align="center" width="150"
                                                 show-overflow-tooltip/>
                                <el-table-column prop="examination_date" label="体检日期" align="center" width="150"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div>
                                            {{scope.row.examination_date|datetime}}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="examination_item" label="体检项目" align="center" width="150"
                                                 show-overflow-tooltip/>
                                <el-table-column prop="treatment_method" label="治疗方式" align="center" width="150"
                                                 show-overflow-tooltip/>
                                <el-table-column prop="hospital" label="医院" align="center" width="150"
                                                 show-overflow-tooltip/>
                                <el-table-column prop="surgery_date" label="手术日期" align="center" width="150"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div>
                                            {{scope.row.surgery_date|datetime}}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="treatment_description" align="center" label="诊断结论及治疗情况描述"
                                                 width="200" show-overflow-tooltip/>
                            </el-table-column>
                            <el-table-column align="center" width="180" label="操作" fixed="right">
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" @click="handleInfo(scope.row)"
                                               v-has="'hie_info'">
                                        详情
                                    </el-button>
                                    <el-button type="text" size="mini" @click="handleUpdate(scope.row)"
                                               v-has="'hie_edit'">
                                        修改
                                    </el-button>
                                    <el-button type="text" size="mini" @click="handleDel(scope.row)"
                                               v-has="'hie_del'">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pager" v-if="table.total > table.pageSize">
                    <el-pagination layout="total, prev, pager, next, sizes"
                                   :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                                   :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                                   @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
                </div>
            </el-col>
        </el-row>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="vmodel"></data-form>
        <del-form :visible="dialogVisibleDel" :form="form" @close="dialogVisibleDel = false" @update="getData"
        ></del-form>
        <edit-form :visible="dialogVisibleEdit" :form="form" @close="dialogVisibleEdit = false" @update="getData"
        ></edit-form>
    </div>
</template>

<script>
    import dataForm from './widgets/dataform'
    import delForm from './widgets/delform'
    import editForm from './widgets/editform'

    export default {
        components: {
            dataForm,
            delForm,
            editForm
        },
        data() {
            return {
                loading: false,
                search: {},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                dialogVisible: false,
                dialogVisibleDel: false,
                dialogVisibleEdit: false,
                form: {},
                recordList: [],
                companyList: [],
                filterText: null
            }
        },
        filters: {
            filtersname: function (value, type, field) {
                if (Array.isArray(value) && value.length > type) {
                    return value[type][field] || "";
                }
                return "";
            },
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        methods: {
            handleExpandChange(row, expandedRows) {
                if (expandedRows.length) {
                    try {
                        this.$http.get(`/company/healthInsurance/${row.user_id}`)
                            .then(resp => {
                                this.recordList = resp.data.data
                            }).catch(err => {
                            console.log(err)
                            this.$message.error(err.message)
                        })
                    } catch (error) {
                        this.$message.error(error.message)
                    }
                }
            },
            handleNodeClick(data) {
                this.search.companyName = data.name
                this.search.companyId = data.id
                this.search.companyPid = data.pid
                this.search.companyLevel = data.level
                this.getData()
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleInfo(row) {
                this.form = JSON.parse(JSON.stringify(row))
                if (this.form.coverage_level) {
                    this.form.coverage_level = [this.form.coverage_level]
                } else {
                    this.form.coverage_level = []
                }
                if (this.form.position_status) {
                    this.form.position_status = [this.form.position_status]
                } else {
                    this.form.position_status = []
                }
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogVisibleEdit = true
            },
            handleDel(row) {
                this.form = JSON.parse(JSON.stringify(row))
                this.dialogVisibleDel = true
            },
            async getData() {
                this.dialogVisible = false
                this.dialogVisibleDel = false
                this.dialogVisibleEdit = false
                this.loading = true
                try {
                    this.$http.get(`/company/healthInsurance?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                            this.loading = false
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            },
            async getDataCompany() {
                this.$http.get(`/company/company/tree`)
                    .then(resp => {
                        this.companyList = resp.data.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
        },
        mounted() {
            this.getDataCompany()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }

    .filter-tree {
        height: calc(100vh - 260px);
        overflow: auto;
    }
</style>
