<template>
    <el-dialog :visible="visible" width="70%" @close="handleClose" class="dialog" :title="title" @open="handelOpen">
        <el-row>
            <el-form class="form" style="height: 500px; overflow-y: auto;overflow-x: hidden;">
                <div class="label">基本信息</div>
                <el-row class="jbxinxi">
                    <el-col :span="12">
                        <div><span>姓名：</span><span>{{ form.name }}</span></div>
                    </el-col>
                    <el-col :span="12">
                        <div><span>性别：</span>      <span v-if="form.sex == '2'"> 女 </span>
                            <span v-if="form.sex == '1'"> 男 </span></div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <span>单位：</span>
                            <span v-if="form.entOrgNameTwo">{{form.entOrgNameTwo}}</span>
                            <span v-if="form.entOrgNameThree">|{{form.entOrgNameThree}}</span>
                            <span v-if="form.entOrgNameFour">|{{form.entOrgNameFour}}</span>
                            <span v-if="form.entOrgNameFive">|{{form.entOrgNameFive}}</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <span>岗位/职务：</span>
                            <span v-if="form.patientInfo">{{form.patientInfo.companyJob}}</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <span>联系电话：</span>
                            <span >{{form.mobile}}</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <span>家属或紧急联系人电话：</span>
                            <span >{{form.ecmobile}}</span>
                        </div>
                    </el-col>
                </el-row>

                <!-- 岗位状况 -->
                <div class="label-title" style="padding-top: 10px">岗位状况：</div>
                <div class="label-title">
                    <el-radio-group v-model="form.position_status">
                        <el-radio label="1" border size="mini">在岗</el-radio>
                        <el-radio label="2" border size="mini">长期离岗</el-radio>
                    </el-radio-group>
                </div>

                <!-- 包保级别 -->
                <div class="label-title">包保级别：</div>
                <div class="label-title">
                <el-radio-group v-model="form.coverage_level" >
                    <el-radio label="三级" border size="mini">三级</el-radio>
                    <el-radio label="二级" border size="mini">二级</el-radio>
                </el-radio-group>
                </div>
                <!-- 异常指标 -->
                <div class="label-title">异常指标：</div>
                <div class="label-title">
                    <el-checkbox-group v-model="form.abnormal_lndicator" size="small">
                        <el-checkbox label="高血压" border></el-checkbox>
                        <el-checkbox label="高血脂" border></el-checkbox>
                        <el-checkbox label="高尿酸" border></el-checkbox>
                        <el-checkbox label="高体重" border></el-checkbox>
                        <el-checkbox label="慢病" border></el-checkbox>
                        <el-checkbox label="大病" border></el-checkbox>
                    </el-checkbox-group>
                </div>
                <!-- 折叠面板 -->
                <el-collapse  accordion>
                    <el-collapse-item title="员工自身职责" name="1">
                        <div class="info">
                            一、员工自身职责<br>
                            1.做好自己健康第一责任人，树立践行健康生活理念。<br>
                            2.按照公司计划，每年主动参加健康体检及职业健康体检，重视体检结果，发现异常指标及时就医治疗。<br>
                            3.正确认识健康包保意义，积极配合包保责任人做好自身健康管理，促进“五高”异常指标下降。<br>
                            4.遵从医嘱，按时、按量服用药物。<br>
                            5.主动到健康之家、健康小屋等健康场所（或使用健康背包）进行指标监测，及时上报监测结果。<br>
                            6.合理膳食，科学运动，戒烟限酒，及时上报运动记录、吸烟量、饮酒量。<br>
                            7.积极参加单位组织的健康宣教活动，主动学习健康知识，了解高血压、糖尿病、心脑血管疾病等慢性病的预防措施，持续提升自身健康素养。<br>
                            8.身体不适时，主动与包保责任人联系，及时就医。<br>
                            9.离庆到异地时，主动、如实向包保责任人汇报健康指标和健康状况。<br>
                            二、员工健康目标<br>
                            通过持续健康包保，实现自身“五高”异常指标恢复正常，降低健康风险。
                        </div>
                    </el-collapse-item>
                </el-collapse>

                <!-- 精准关怀 -->
                <div class="label">精准关怀</div>
                <div style="padding-left: 10px">
                    <span>精准关怀体检日期：</span>
                    <el-date-picker v-model="form.examination_date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" size="mini"></el-date-picker>
                </div>
                <div class="label-title">精准关怀体检项目：</div>
                <div class="label-title">
                    <el-radio-group v-model="form.examination_item" size="small">
                        <el-radio label="头颈+冠脉CTA颅脑MRA" border size="mini" @click.native="handleClick('头颈+冠脉CTA颅脑MRA','examination_item')">头颈+冠脉CTA颅脑MRA</el-radio>
                        <el-radio label="头颈、冠脉二合一CTA" border size="mini" @click.native="handleClick('头颈、冠脉二合一CTA','examination_item')">头颈、冠脉二合一CTA</el-radio>
                        <el-radio label="冠脉CTA" border size="mini" @click.native="handleClick('冠脉CTA','examination_item')">冠脉CTA</el-radio>
                        <el-radio label="颅脑MRA" border size="mini" @click.native="handleClick('颅脑MRA','examination_item')">颅脑MRA</el-radio>
                        <el-radio label="冠脉CTA颅脑MRA" border size="mini" @click.native="handleClick('冠脉CTA颅脑MRA','examination_item')">冠脉CTA颅脑MRA</el-radio>
                    </el-radio-group>
                </div>
                <div class="label-title">治疗形式：</div>
                <div class="label-title">
                    <el-radio-group v-model="form.treatment_method" size="small">
                        <el-radio label="药物指导治疗" border size="mini"  @click.native="handleClick('药物指导治疗','treatment_method')">药物指导治疗</el-radio>
                        <el-radio label="入院药物治疗" border size="mini" @click.native="handleClick('入院药物治疗','treatment_method')">入院药物治疗</el-radio>
                        <el-radio label="入院手术治疗" border size="mini" @click.native="handleClick('入院手术治疗','treatment_method')">入院手术治疗</el-radio>
                    </el-radio-group>
                </div>
                <div style="padding-left: 10px">
                    <span>治疗日期: </span>
                    <el-date-picker v-model="form.surgery_date"  value-format="yyyy-MM-dd" type="date" placeholder="选择日期" size="mini"></el-date-picker>
                </div>
                <div class="label-title">精准关怀体检医院：</div>
                <div class="label-title">
                    <el-radio-group v-model="form.hospital" size="small">
                        <el-radio label="油田总院" border size="mini" @click.native="handleClick('人民医院','hospital')">油田总院</el-radio>
                        <el-radio label="龙南医院" border size="mini" @click.native="handleClick('龙南医院','hospital')">龙南医院</el-radio>
                        <el-radio label="人民医院" border size="mini" @click.native="handleClick('人民医院','hospital')">人民医院</el-radio>
                    </el-radio-group>
                </div>
                <div class="label-title">检前状况描述：</div>
                <div class="label-title">
                <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入检前状况描述"
                        v-model="form.examination_condition">
                </el-input>
                </div>
                <div class="label-title">诊断结论及治疗情况描述：</div>
                <div class="label-title">
                    <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入诊断结论及治疗情况描述"
                            v-model="form.treatment_description">
                    </el-input>
                </div>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button type="" @click="handleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            title: {
                default: "详情"
            },
            form: {
                default: {
                    patient:{},
                    abnormal_lndicator:[]
                }
            },
        },
        data() {
            return {
                loading: false,
                cpform:{}
            }
        },
        filters: {},
        methods: {
            handleClick(value,type) {
                // 如果当前值和之前值相同，则取消选择
                if (value === this.cpform[type]) {
                    this.cpform[type] = null;
                    this.form[type] = null;
                } else {
                    this.cpform[type]= value;
                }
            },
            handleClose() {
                this.$emit('close')
            },
            onSubmit(){
                try {
                    this.loading = true
                    var dataForm = {
                        id:this.form.did,
                        abnormal_lndicator:this.form.abnormal_lndicator,
                        examination_condition:this.form.examination_condition,
                        hospital:this.form.hospital,
                        treatment_method:this.form.treatment_method,
                        surgery_date:this.form.surgery_date,
                        examination_item:this.form.examination_item,
                        examination_date:this.form.examination_date,
                        coverage_level:this.form.coverage_level,
                        treatment_description:this.form.treatment_description,
                        position_status:this.form.position_status,
                    }
                    this.$http.post(`/company/healthInsurance/baobao`,dataForm)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success')  this.$emit('update')
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                    this.loading = false
                }
            },
            handelOpen() {
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }
    ::v-deep .el-checkbox.is-bordered.el-checkbox--small{
        margin: 5px;
    }
    ::v-deep .el-radio{
        margin: 5px;
    }
    .label {
        /*height: 40px;*/
        padding: 15px 20px;
        font-size: 15px;
        /*line-height: 40px;*/
        position: relative;
        white-space: normal; /* 允许内容换行 */
        overflow-wrap: break-word; /* 在长单词或URL等无法在行内完全显示时断开 */
        word-wrap: break-word;
    }

    .label::before {
        content: '';
        height: 14px;
        width: 3px;
        background: #1989fa;
        position: absolute;
        left: 10px;
        top: calc(50% - 7px);
    }
    .jbxinxi{
        ::v-deep .el-col-12{
            padding: 5px 10px;
        }
    }
    .label-title{
        color: #646566;
        padding: 5px 10px;
        font-size: 14px;
        position: relative;
        white-space: normal; /* 允许内容换行 */
        overflow-wrap: break-word; /* 在长单词或URL等无法在行内完全显示时断开 */
        word-wrap: break-word;
        background: white;
    }
</style>
