<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handleDownload" icon="el-icon-download" type="defalut" v-has="'batch_phr_download'">导出</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康档案"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-form-item label="工作单位">
                    <cascader-company-name v-model="search.companyName"></cascader-company-name>
                </el-form-item>
                <el-form-item label="搜索" label-width="40px">
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:90px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                            <el-option label="手机号" value="mobile"></el-option>
                            <el-option label="单位" value="companyName"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="报告筛选">
                    <el-select v-model="search.reportType" placeholder="请选择" style="width:180px" clearable>
                        <el-option label="体检报告" value="1"></el-option>
                        <el-option label="小屋报告" value="2"></el-option>
<!--                        <el-option label="手工体检表" value="3"></el-option>-->
<!--                        <el-option label="文件上传" value="4"></el-option>-->
<!--                        <el-option label="体检提醒" value="5"></el-option>-->
                    </el-select>
                </el-form-item>
                <el-form-item label="-" label-width="8px">
                    <el-date-picker v-model="search.reportTime" type="daterange" range-separator="至" clearable
                                    style="width: 300px"
                                    :disabled="search.reportType?false:true"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="风险人群" label-width="70px">
                    <el-select v-model="search.risklevel" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="健康人群" value="健康人群"></el-option>
                        <el-option label="风险人群" value="风险人群"></el-option>
                        <el-option label="异常人群" value="异常人群"></el-option>
                        <el-option label="慢病人群" value="慢病人群"></el-option>
                        <el-option label="大病人群" value="大病人群"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人群等级" label-width="70px">
                    <el-select v-model="search.level" placeholder="请选择" style="width:100%" filterable allow-create
                               default-first-option clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in tagList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指标等级" label-width="70px">
                    <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable
                                 v-model="search.risklevelList"></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'phr_search'">查询</el-button>
                    <el-button type="" plain @click="reset" v-has="'phr_search'">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="search" style="background-color: #fff">
            <div class="btns">
                <el-button type="primary" size="mini" @click="handleUpload"  v-has="'phr_upload'">体检报告上传
                </el-button>
                <el-button type="primary" size="mini" @click="handleAdd" :disabled="idList.length?false:true" v-has="'phr_plgy'">批量干预
                </el-button>
                <el-button type="primary" size="mini" @click="handleReminder" :disabled="idList.length?false:true" v-has="'phr_cltx'">
                    发送体检测量提醒
                </el-button>
            </div>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="mini" border v-loading="loading" width="100%"
                          height="100%"
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="基本信息" width="">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.sex=='2'"> 女 </span>
                                <span v-if="scope.row.sex=='1'"> 男 </span>
                                <span v-if="scope.row.age">{{scope.row.age }} 岁</span>
                            </div>
                            <div>
                                <small> {{ isFullIdCard[scope.$index] ? scope.row.idCard :
                                    maskIdCardRegex(scope.row.idCard) }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                   @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="客户标签" width="100">
                        <template slot-scope="scope">
                            <popover-JCXM :tagList="scope.row"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="联系方式" width="">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                            </div>
                            <div>{{ scope.row.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="单位分组" width="">
                        <template slot-scope="scope">
                            <div>{{ scope.row.wpName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="体检报告" width="">
                        <template slot-scope="scope">
                            <div>{{ scope.row.healthReportTime || '无' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="小屋报告" width="">
                        <template slot-scope="scope">
                            <div>{{ scope.row.houseReportTime || '无' }}</div>
                        </template>
                    </el-table-column>
<!--                    <el-table-column align="center" label="上传文件" width="140">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div>{{ scope.row.fileReportTime || '无' }}</div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column align="center" label="最近提醒" width="140">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div>{{ scope.row.physicalNotifyTime || '无' }}</div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)" v-has="'phr_ckda'">查看档案
                            </el-button>
                            <!-- <el-button type="text" size="mini" @click="handleDowload(scope.row)">下载健康报告
                            </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <data-form :visible="dialogVisible" :localForm="form" @close="dialogVisible = false"
                   @update="getData" :idList="idList"></data-form>
        <upload-form :visible="dialogVisibleUpload" @close="dialogVisibleUpload = false"></upload-form>
        <el-dialog
                :visible.sync="dialogVisibleExport"
                title="提示"
                width="30%"
        >
            <p>导出的数据不能超过2万条，请缩小查询范围或导出当前范围内的前2万条数据。</p>
            <p>导出时请不要关闭页面。</p>
            <el-progress :percentage="progress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleExport = false" :disabled="but_disabled" v-loading="but_loading">取消</el-button>
                <el-button type="primary" @click="exportLimitedData" :disabled="but_disabled" v-loading="but_loading">导 出</el-button>
            </span>
        </el-dialog>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../data/formatter"
    import {dbList, dwList, grList, pgList} from "../../data/utlisData"
    import dataForm from "../hi/widgets/dataForm";
    import CascaderCompanyName from "../../components/form/cascaderCompanyName";
    import uploadForm from "./widgets/uploadForm";

    const makeInitSearch = () => {
        return {
            companyName: "",
            // wpName: "",
            key: "name",
            value: "",
            reportType: "",
            reportTime: [],
            // tag: "1",
            // grTagList: [],
            // evalTags: [],
            // type: "0",
            risklevelList: [],
            level: "",
            risklevel: ""
        }
    }


    export default {
        name: 'phr',
        components: {
            CascaderCompanyName,
            dataForm,
            uploadForm
        },
        data() {
            return {
                dialogVisibleUpload: false,
                but_loading: false,
                but_disabled: false,
                gzqList: [],
                dbList: dbList,
                defaultProps: {
                    multiple: true,
                    children: 'tags',
                    label: 'name',
                    value: 'value',
                    emitPath: false
                },
                data: {},
                isFullIdCard: {},
                tagList: [
                    {
                        id: 3,
                        name: "三级"
                    },
                    {
                        id: 2,
                        name: "二级"
                    },
                    {
                        id: 1,
                        name: "一级"
                    },
                ],
                form: {},
                dialogVisible: false,
                formatter: formatter,
                dwList: dwList,
                grList: grList,
                pgList: pgList,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                idList: [],
                dialogVisibleExport: false,
                progress: 0, // 进度条的百分比
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(from.path.indexOf("/main/phr/detail/")>-1){
                    vm.handlePopState()
                }
            });
        },
        methods: {
            handleUpload(){
                this.dialogVisibleUpload = true
            },
            async handleDownload() {
                this.progress = 0; // 初始化进度
                this.dialogVisibleExport = true; // 显示进度条对话框
                // 模拟导出数据的过程
            },
            async exportLimitedData() {
                try {
                    this.but_loading = true
                    this.but_disabled = true
                    this.$http({
                        url: `/phr/export`,
                        method: 'POST',
                        responseType: 'blob', // 重要：确保响应类型为blob
                        data: this.search,
                        headers: {},
                        onDownloadProgress:(progressEvent)=>{
                            let progressBar = Math.round( progressEvent.loaded / progressEvent.total*100);
                            //接收进度为99%的时候需要留一点前端编译的时间
                            if(progressBar >= 99){
                                this.progress = 99;
                            }else{
                                this.progress = progressBar;
                            }
                        }
                    }).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        let fileName = 'default.xlsx';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                            if (fileNameMatch && fileNameMatch.length === 2) {
                                fileName = decodeURIComponent(fileNameMatch[1]);
                            }
                        }
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url); // 清理
                        this.but_loading = false
                        this.but_disabled = false
                        this.dialogVisibleExport = false; // 隐藏导出进度对话框
                        this.$message.success('数据导出完成！');
                    }).catch((error) => {
                        this.but_loading = false
                        this.but_disabled = false
                        this.$message({
                            message: error,
                            type: 'error'
                        });
                    });
                } catch (error) {
                    this.but_loading = false
                    this.but_disabled = false
                    this.$message({
                        message: error,
                        type: 'error'
                    });
                }
            },
            handlePopState() {
                const savedConditions = localStorage.getItem('searchConditions');
                const black = localStorage.getItem('back');
                if (savedConditions && black) {
                    this.search = JSON.parse(savedConditions);
                }
            },
            handelRadio() {
                this.search.evalTags = []
                this.search.grTagList = []
            },
            handelNode() {
                var tagList = []
                this.$refs.tree.getCheckedNodes().forEach(function (item) {
                    tagList.push(item.name)
                })
                this.search.evalTags = tagList
                this.getData()
            },
            maskIdCardRegex(idCard) {
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handleAdd() {
                this.form = {
                    plans: [],
                    healthGuidance: {},
                    image: [],
                    video: [],
                    supplementContent: {}
                }
                this.dialogVisible = true
            },
            handleReminder() {
                this.$message({
                    message: '开发中',
                    type: 'info'
                });
                // if (this.form.idList.length == 0) {
                //     this.$message({
                //         message: '最少要选择一个对象',
                //         type: 'warning'
                //     });
                //     return
                // }
                // this.form.KHBQ = []
                // this.dialogVisible = true
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                    localStorage.removeItem('searchConditions');
                    localStorage.removeItem('back');
                }, 0);
            },
            handleRowChange(row) {
                this.$router.push({path: `/main/phr/detail/${row.id}`})
            },
            handleSelectionChange(val) {
                var that = this
                that.idList = val
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/phr`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                    localStorage.setItem('searchConditions',JSON.stringify(this.search));

                }
                this.loading = false
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            }
        },
        mounted() {
            this.getUserOwn()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0 !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
