<template>
    <div class="page">
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-form-item label="任务日期">
                    <el-date-picker
                            style="width: 300px"
                            v-model="search.planExeTime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
<!--                <el-form-item label="单位分组">-->
<!--                    <el-select v-model="search.type" placeholder="" style="width:100%">-->
<!--                        <el-option label="全部" value="1"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="所属方案">
                    <el-input v-model="search.programName" placeholder="请输入方案名称" clearable/>
                </el-form-item>
                <el-form-item label="形式">
                    <el-select v-model="search.interveneModus" placeholder="请输入选择形式" style="width:100%" clearable>
                        <el-option label="电话" value="1"></el-option>
                        <el-option label="微信" value="3"></el-option>
                        <el-option label="随访问卷" value="8"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别">
                    <el-select v-model="search.interveneCategory" placeholder="请输入选择类别" style="width:100%" clearable>
                        <el-option label="随访" value="1"></el-option>
                        <el-option label="健康教育" value="2"></el-option>
                        <el-option label="膳食指导" value="3"></el-option>
                        <el-option label="运动指导" value="4"></el-option>
                        <el-option label="生活方式指导" value="5"></el-option>
                        <el-option label="心理指导" value="6"></el-option>
                        <el-option label="用药指导" value="7"></el-option>
                        <el-option label="其它" value="8"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="任务级别">
                    <el-select v-model="search.level" placeholder="请输入选择任务级别" style="width:100%" clearable>
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                        <el-option label="4" value="4"></el-option>
                        <el-option label="5" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="任务状态">
                    <el-select v-model="search.status" placeholder="请输入选择任务状态" style="width:100%" clearable>
                        <el-option label="定时待发送" value="1"></el-option>
                        <el-option label="已完成" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%"
                  height="calc(100vh - 480px)">
            <el-table-column type="index" label="序号" align="center"
                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                             width="55"/>
            <el-table-column prop="planExeTime" label="计划执行时间" width="" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{scope.row.planExeTime|dateFormat}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="actualExeTime" label="执行时间" width="" show-overflow-tooltip>
<!--                <template slot-scope="scope">-->
<!--                    <span>第 {{scope.row.planTimeValue}}天 </span>-->
<!--                    <span> {{scope.row.planTime}}</span>-->
<!--                </template>-->
            </el-table-column>
            <el-table-column prop="userName" label="姓名" width="" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="programName" label="所属方案" width="" show-overflow-tooltip/>
            <el-table-column prop="interveneModus" label="形式" width="" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.interveneModus=='1'">电话</span>
                    <span v-else-if="scope.row.interveneModus=='3'">微信</span>
                    <span v-else-if="scope.row.interveneModus=='8'">随访问卷</span>
                    <span v-else>{{scope.row.interveneModus}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="interveneCategory" label="类型" width="" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.interveneCategory=='1'">随访</span>
                    <span v-else-if="scope.row.interveneCategory=='2'">健康教育</span>
                    <span v-else-if="scope.row.interveneCategory=='3'">膳食指导</span>
                    <span v-else-if="scope.row.interveneCategory=='4'">运动指导</span>
                    <span v-else-if="scope.row.interveneCategory=='5'">生活方式指导</span>
                    <span v-else-if="scope.row.interveneCategory=='6'">心理指导</span>
                    <span v-else-if="scope.row.interveneCategory=='7'">用药指导</span>
                    <span v-else-if="scope.row.interveneCategory=='8'">其它</span>
                    <span v-else>{{scope.row.interveneCategory}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="content" label="干预内容" width="" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.interveneModus == '3'">{{JSON.parse(scope.row.content).keyword3}}</span>
                    <span v-else>{{scope.row.content}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="level" label="紧急程度" width="" show-overflow-tooltip/>
            <el-table-column prop="status" label="状态" width="" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.status=='1'">定时待发送</span>
                    <span v-else-if="scope.row.status=='2'">已完成</span>
                </template>
            </el-table-column>
<!--            <el-table-column label="操作">-->
<!--                <template slot-scope="scope">-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                search: {status: ''},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                }
            }
        },
        filters:{
            dateFormat(value) {
                if (!value) return '';
                let date = new Date(value)
                return date.format('yyyy-MM-dd')
            }
        },
        methods: {
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                let pk = this.$route.params.id
                const resp = await this.$http.get(`/gyjh/list/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>


    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }


    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
