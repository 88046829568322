<template>
    <el-dialog :visible="visible" width="70%" @close="handleClose" class="dialog" :title="title">
        <el-tabs tab-position="left" style="height: 750px;" @tab-click="handleClick" v-model="activeName"
                 v-if="dataTable.length">
            <el-tab-pane :label="item.created_at + '五高问卷'" :name="index.toString()" v-for="(item,index) in dataTable"
                         :key="index">
                <el-row v-if="item.status =='2'" class="row">
                    <el-row>
                        <el-col :span="12" >项目</el-col>
                        <el-col :span="8">结果</el-col>
                        <el-col :span="4">单位</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" >身高</el-col>
                        <el-col :span="8">{{item.content.height}}</el-col>
                        <el-col :span="4">cm</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">体重</el-col>
                        <el-col :span="8">{{item.content.weight}}</el-col>
                        <el-col :span="4">kg</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">总胆固醇</el-col>
                        <el-col :span="8">{{item.content.chol}}</el-col>
                        <el-col :span="4">mmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">甘油三酯</el-col>
                        <el-col :span="8">{{item.content.tg}}</el-col>
                        <el-col :span="4">mmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">高密度脂蛋白</el-col>
                        <el-col :span="8">{{item.content.hdl}}</el-col>
                        <el-col :span="4">mmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">低密度脂蛋白</el-col>
                        <el-col :span="8">{{item.content.ldl}}</el-col>
                        <el-col :span="4">mmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">收缩压</el-col>
                        <el-col :span="8">{{item.content.sbp}}</el-col>
                        <el-col :span="4">mmHg</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">舒张压</el-col>
                        <el-col :span="8">{{item.content.dbp}}</el-col>
                        <el-col :span="4">mmHg</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">血糖</el-col>
                        <el-col :span="8">{{item.content.fbg}}</el-col>
                        <el-col :span="4">mmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">糖化血红蛋白</el-col>
                        <el-col :span="8">{{item.content.hba1c}}</el-col>
                        <el-col :span="4">mmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">尿酸</el-col>
                        <el-col :span="8">{{item.content.urea}}</el-col>
                        <el-col :span="4">μmol/L</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">肌酐</el-col>
                        <el-col :span="8">{{item.content.cr}}</el-col>
                        <el-col :span="4">μmol/L</el-col>
                    </el-row>
                </el-row>
                <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
        </el-tabs>
        <el-empty description="暂无数据" v-else></el-empty>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            title: {},
        },
        data() {
            return {
                loading: false,
                activeName: "",
                activetab: "1",
                form: {},
                dataTable: [],
            }
        },
        methods: {
            handleClick() {
                this.form = this.dataTable[this.activeName]
                if(this.form.questionnaireStatus !=="3"){
                    this.form = this.form1
                }
            },
            async getData(id) {
                const resp = await this.$http.get(`/wenjuan/wg/${id}`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.dataTable = resp.data.data
                    if (this.dataTable.length) {
                        this.form = this.dataTable[0]
                        if(this.form.questionnaireStatus !=="3"){
                            this.form = this.form1
                        }
                    }
                }
            },
            handleClose() {
                this.$emit('close')
            },
        },
    }
</script>

<style lang="scss" scoped>
    .row{
        ::v-deep .el-col{
            padding: 10px;
        }
        ::v-deep .el-row{
            border-bottom: 1px solid #99a9bf;
        }
    }
</style>
