import formatter from "@/data/formatter"
const data = {
    table: {
        columns: [
            {title: '方案开始日期', field: 'XM', width: ''},
            {title: '方案结束日期', field: 'XB', width: ''},
            {title: '方案名称', field: 'NL', width: ''},
            {title: '方案简介', field: 'DH', width: ''},
            {title: '执行单位分组', field: 'SFZ', width: ''},
            {title: '状态', field: 'KHBQ', width: ''},
            {title: '执行医生', field: 'GZQ', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
    baseinfo: {
        columns: [
            {title: '创建时间', field: 'createdAt', width: ''},
            {title: '用户姓名', field: 'name', width: ''},
            {title: '事件类型', field: 'type', width: ''},
            {title: '数据来源', field: 'source', width: ''},
            {title: '事件类容', field: 'content', width: ''},
            {title: '状态', field: 'status', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
    plantable: {
        columns: [
            {title: '方案开始日期', field: 'startTime', width: '',formatter:formatter.formatDateDay},
            {title: '方案结束日期', field: 'endTime', width: '',formatter:formatter.formatDateDay},
            {title: '方案名称', field: 'name', width: ''},
            {title: '方案简介', field: 'introduction', width: ''},
            {title: '执行单位分组', field: 'workspaceName', width: ''},
            {title: '状态', field: 'status', width: '',formatter:formatter.formatFstatus},
            {title: '执行医生', field: 'doctor', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },

    reportable: {
        columns: [
            {title: '报告时间', field: 'BGSJ', width: ''},
            {title: '机构', field: 'JG', width: ''},
            {title: '报告来源', field: 'BGLY', width: ''},
            {title: '报告类型', field: 'BGLX', width: ''},
            {title: '标签数量', field: 'BQSL', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
    assessmenttable: {
        columns: [
            {title: '问卷开始时间', field: 'created_at', width: ''},
            {title: '问卷提交时间', field: 'WJTJSJ', width: ''},
            {title: '评估报告发送时间', field: 'PGGGFSSJ', width: ''},
            {title: '类型', field: 'LX', width: ''},
            {title: '状态', field: 'ZT', width: ''},
            {title: '健康评估标签', field: 'JKPGBQ', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    },
}

export default data
