<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handleAdd" icon="el-icon-plus" type="primary" v-has="'create_admin'">新建记录</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh"
                           class="hidden-sm-only hidden-sm-only">刷新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="管理员管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="工作单位">
                    <cascaderCompanyName v-model="search.companyName"></cascaderCompanyName>
                </el-form-item>
                <el-form-item label="登录账号">
                    <el-input v-model="search.name" placeholder="请输入登录账号" clearable/>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="search.nickname" placeholder="请输入名称" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search" v-has="'admin_check'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%" height="100%"
                          :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="username" label="登录账号" width="" show-overflow-tooltip/>
                    <el-table-column prop="nickname" label="管理员名称" width="" show-overflow-tooltip/>
                    <el-table-column prop="companyName" label="所属单位" width="" show-overflow-tooltip/>
                    <el-table-column prop="wpName" label="单位分组" width="" show-overflow-tooltip/>
                    <el-table-column prop="level" label="单位等级" width="120" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.level==1">一级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==2">二级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==3 && scope.row.entOrgNameThree.includes('机关东区')">超三级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==3 && scope.row.entOrgNameThree.includes('机关西区')">超三级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==3 ">三级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==4">四级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==4 && scope.row.entOrgNameThree.includes('机关东区')">超四级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==4 && scope.row.entOrgNameThree.includes('机关西区')">超四级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==5">五级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==5 && scope.row.entOrgNameThree.includes('机关东区')">超五级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==5 && scope.row.entOrgNameThree.includes('机关西区')">超五级单位</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="roleName" label="所属角色" width="" show-overflow-tooltip/>
                    <el-table-column prop="status" align="center" label="启动状态" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-switch
                                    disabled
                                    v-model="scope.row.status"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_type" align="center" label="用户类型" width="120" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.user_type=='1'" tyep="">系统管理员</el-tag>
                            <el-tag v-if="scope.row.user_type=='4'" tyep="">企业端+医生端</el-tag>
                            <el-tag v-else-if="scope.row.user_type=='2'">企业端</el-tag>
                            <el-tag v-else-if="scope.row.user_type=='3'">医生端</el-tag>
                            <el-tag v-else-if="scope.row.user_type=='5'">值班医生</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_name" label="创建人" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width="" show-overflow-tooltip>
                        <template slot-scope="scope">{{scope.row.create_time|datetime}}</template>
                    </el-table-column>
                    <el-table-column align="center" width="260" label="操作">
                        <template slot-scope="scope">
                            <el-button type="warning" plain size="mini" @click="handleUpdate(scope.row)"  v-has="'admin_edit'">
                                修 改
                            </el-button>
                            <el-button type="warning" plain size="mini" @click="handleRepasswd(scope.row)" v-has="'admin_repasswd'">
                                重置密码
                            </el-button>
<!--                            <el-button type="danger" plain size="mini" @click="handleDelete(scope.row)"  v-has="'admin_delete'">-->
<!--                                删 除-->
<!--                            </el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="vmodel" :gzqList="gzqList"></data-form>
        <reset-passwd-form :visible="passwddialogVisible" :form="form"
                           @close="passwddialogVisible = false"></reset-passwd-form>
    </div>
</template>

<script>
    import dataForm from './widgets/dataform'
    import ResetPasswdForm from './widgets/resetPasswdform'

    export default {
        name: 'main-settings-user',
        path: '/main/settings/user',
        components: {
            dataForm,
            ResetPasswdForm,
        },
        data() {
            return {
                loading: false,
                search: {
                    companyName:""
                },
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                dialogVisible: false,
                delmodel: false,
                passwddialogVisible: false,
                form: {},
                gzqList:[]
            }
        },
        methods: {
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.username} 账号吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/auth/user/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            handleAdd() {
                this.dialogVisible = true
                this.form = {
                    "name": "",
                    "roleIds": [],
                    "password": ""
                }
            },
            handleRepasswd(row) {
                this.form = {
                    id: row.id,
                    username: row.username,
                    password: '',
                    new_password: '',
                    new_password2: '',
                }
                this.passwddialogVisible = true
            },
            handleUpdate(row) {
                this.form = JSON.parse(JSON.stringify(row))
                if(this.form.company){
                    this.form.company = this.form.company.map(str => parseInt(str, 10));
                    if(this.form.user_type != "3"){
                        this.form.company = this.form.company[0]
                    }
                }
                this.dialogVisible = true
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                try {
                    this.search.page = this.table.page
                    this.search.pageSize = this.table.pageSize
                    this.$http.post(`/auth/list`,  this.search)
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                            this.loading = false
                        }).catch(err => {
                        this.loading = false
                        console.log(err)
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            }
        },
        mounted() {
            this.getUserOwn()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
