<template>
    <el-dialog :visible="visible" width="50%" @close="handleClose" class="dialog" :title="title" @open="handelOpen">
        <el-row>
            <el-table :data="b_list" stripe size="small" v-loading="loading" width="100%"
                      border>
                <el-table-column align="center" label="基本信息" width="160">
                    <template slot-scope="scope">
                        <div>
                            <span>{{ scope.row.name }}</span>
                            <span v-if="scope.row.sex == '2'"> 女 </span>
                            <span v-if="scope.row.sex == '1'"> 男 </span>
                            <span v-if="scope.row.patientInfo">{{scope.row.patientInfo.companyJob}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="单位" width="200px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                        <span v-if="scope.row.entOrgNameThree">|{{scope.row.entOrgNameThree}}</span>
                        <span v-if="scope.row.entOrgNameFour">|{{scope.row.entOrgNameFour}}</span>
                        <span v-if="scope.row.entOrgNameFive">|{{scope.row.entOrgNameFive}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="mobile" label="联系电话" width="" align="center"
                                 show-overflow-tooltip/>
                <el-table-column align="center" width="180" label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="handleDelete(scope.row)" v-has="'expert_edit'">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "详情"
            },
        },
        data() {
            return {
                b_list: [],
                loading:false
            }
        },
        filters: {},
        methods: {
            handleClose() {
                this.$emit('close')
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.name} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/company/healthInsurance/baobao/${row.bid}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success')  this.$emit('update')
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handeBaobao() {
                try {
                    this.loading = true
                    this.$http.get(`/company/healthInsurance/baobao/${this.form.user_id}`)
                        .then(resp => {
                            this.b_list = resp.data.data
                            this.loading = false
                        }).catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.$message.error(error.message)
                    this.loading = false
                }
            },
            handelOpen() {
                this.handeBaobao()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }
</style>
