<template>
  <div class="page">
    <el-page-header @back="$router.go(-1)" content="绑定微信公众号"/>
    <div style="text-align: center;margin-top: 200px">
      <img :src="form.url" style="width: 200px;height: 200px">
      <p v-if="form.openid">已绑定</p>
      <p v-else>请用微信扫码，绑定微信公众号！</p>
    </div>
  </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                form: ""
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get(`/wechat/qrcode/getWXPublicQRCode`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.form = resp.data.data
                }
            },
        },
        mounted() {
            this.getData()
        }
    }

</script>

<style scoped>

</style>
