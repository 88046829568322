const data = {
    table: {
        columns: [],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
    companyMenu:[
        {
            id: '10',
            label: '医生工作台',
            path: '/main/dashboard',
            icon: 'el-icon-view',
        },
        {
            id: '20',
            label: '健康档案',
            path: '/main/phr',
            icon: 'el-icon-brush',
            children: [
                {
                    id: '20-1',
                    label: '档案进度',
                    path: '/main/phr/progress',
                    children: [
                        {
                            "id": "20-1-7",
                            "children": [],
                            "name": "查询",
                            "permission": "progress_search",
                            "label": "查询",
                        },
                        {
                            "id": "20-1-1",
                            "children": [],
                            "name": "发送激活短信",
                            "permission": "progress_sms",
                            "label": "发送激活短信"
                        },
                        {
                            "id": "20-1-2",
                            "children": [],
                            "name": "发送基础问卷",
                            "permission": "progress_jcwj",
                            "label": "发送基础问卷",
                        },
                        {
                            "id": "20-1-3",
                            "children": [],
                            "name": "批量催办",
                            "permission": "progress_cb",
                            "label": "批量催办",
                        },
                        {
                            "id": "20-1-4",
                            "name": "查看问卷",
                            "children": [],
                            "permission": "progress_ckwj",
                            "label": "查看问卷",
                        },
                        {
                            "id": "20-1-5",
                            "children": [],
                            "name": "手工建档",
                            "label": "手工建档",
                            "permission": "progress_sgjd",
                        },
                        {
                            "id": "20-1-6",
                            "children": [],
                            "name": "查看档案",
                            "label": "查看档案",
                            "permission": "progress_ckda",
                        },
                        {
                            "id": "20-1-8",
                            "children": [],
                            "name": "批量导出",
                            "permission": "batch_progress_download",
                            "label": "批量导出",
                        },
                    ],
                },
                {
                    id: '20-2',
                    label: '健康档案',
                    path: '/main/phr',
                    children: [
                        {
                            "id": "20-2-4",
                            "children": [],
                            "name": "查询",
                            "permission": "phr_search",
                            "label": "查询",
                        },
                        {
                            "id": "20-2-1",
                            "children": [],
                            "name": "批量干预",
                            "permission": "phr_plgy",
                            "label": "批量干预"
                        },
                        {
                            "id": "20-2-2",
                            "children": [],
                            "name": "发送体检测量提醒",
                            "permission": "phr_cltx",
                            "label": "发送体检测量提醒",
                        },
                        {
                            "id": "20-2-3",
                            "children": [],
                            "name": "查看档案",
                            "permission": "phr_ckda",
                            "label": "查看档案",
                        },
                        {
                            "id": "20-2-5",
                            "children": [],
                            "name": "批量导出",
                            "permission": "batch_phr_download",
                            "label": "批量导出",
                        },
                        {
                            "id": "20-2-6",
                            "children": [],
                            "name": "体检报告上传",
                            "permission": "phr_upload",
                            "label": "体检报告上传",
                        },
                        {
                            "id": "20-2-7",
                            "children": [],
                            "name": "查看干预详情",
                            "permission": "itp_info",
                            "label": "查看干预详情",
                        },
                        {
                            "id": "20-2-8",
                            "children": [],
                            "name": "删除干预方案",
                            "permission": "itp_del",
                            "label": "删除干预方案",
                        },
                    ],
                },
            ]
        },
        {
            id: '30',
            label: '健康评估',
            path: '/main/healthAssessment/hra3',
            icon: 'el-icon-star-off',
            children: [
                {
                    "id": "30-1-7",
                    "children": [],
                    "name": "查询",
                    "permission": "hra3_search",
                    "label": "查询",
                },
                {
                    "id": "30-1-1",
                    "children": [],
                    "name": "发送评估问卷",
                    "permission": "hra3_wj",
                    "label": "发送评估问卷"
                },
                {
                    "id": "30-1-2",
                    "children": [],
                    "name": "批量催办",
                    "permission": "hra3_cb",
                    "label": "批量催办",
                },
                {
                    "id": "30-1-3",
                    "children": [],
                    "name": "评估详情",
                    "permission": "hra3_xq",
                    "label": "评估详情",
                },
                {
                    "id": "30-1-4",
                    "name": "健康档案",
                    "children": [],
                    "permission": "hra3_da",
                    "label": "健康档案",
                },
                {
                    "id": "30-1-5",
                    "children": [],
                    "name": "人工评估",
                    "label": "人工评估",
                    "permission": "hra3_rgpg",
                },
                {
                    "id": "30-1-6",
                    "children": [],
                    "name": "问卷评估",
                    "label": "问卷评估",
                    "permission": "hra3_wjpg",
                },
                {
                    "id": "30-1-8",
                    "children": [],
                    "name": "批量导出",
                    "permission": "batch_hra3_download",
                    "label": "批量导出",
                },
            ],
        },
        {
            id: '40',
            label: '心理评估',
            path: '/main/assessment/person',
            icon: 'el-icon-star-off',
        },
        {
            id: '50',
            label: '健康干预',
            path: '/main/hi',
            icon: 'el-icon-s-opportunity',
            children: [
                {
                    id: '50-1',
                    label: '干预方案',
                    path: '/main/hi/task',
                    children: [
                        {
                            "id": "50-1-4",
                            "children": [],
                            "name": "查询",
                            "permission": "task_search",
                            "label": "查询",
                        },
                        {
                            "id": "50-1-1",
                            "children": [],
                            "name": "批量制定干预方案",
                            "permission": "task_zdgyfa",
                            "label": "批量制定干预方案"
                        },
                        {
                            "id": "50-1-2",
                            "children": [],
                            "name": "查看方案",
                            "permission": "task_ckfa",
                            "label": "查看方案",
                        },
                        {
                            "id": "50-1-3",
                            "name": "健康档案",
                            "children": [],
                            "permission": "task_da",
                            "label": "健康档案",
                        },
                        {
                            "id": "50-1-5",
                            "children": [],
                            "name": "批量导出",
                            "permission": "batch_hi_download",
                            "label": "批量导出",
                        },
                    ],
                },
                {
                    id: '50-2',
                    label: '干预模板',
                    path: '/main/hi/model',
                    children: [
                        {
                            "id": "50-2-3",
                            "children": [],
                            "name": "查询",
                            "permission": "model_search",
                            "label": "查询",
                        },
                        {
                            "id": "50-2-1",
                            "children": [],
                            "name": "添加",
                            "permission": "model_add",
                            "label": "添加"
                        },
                        {
                            "id": "50-2-2",
                            "children": [],
                            "name": "编辑",
                            "permission": "model_edit",
                            "label": "编辑",
                        }
                    ],
                },
            ]
        },
        {
            id: '60',
            label: '问卷量表',
            path: '/main/psychological',
            icon: 'el-icon-data-line',
            children: [
                {
                    id: '60-5',
                    label: '五高问卷',
                    path: '/main/evaluate/fivehighs',
                    children: [
                        {
                            "id": "60-5-2",
                            "children": [],
                            "name": "查询",
                            "permission": "fivehighs_search",
                            "label": "查询",
                        },
                        {
                            "id": "60-5-1",
                            "children": [],
                            "name": "发送五高问卷",
                            "permission": "fivehighs_send",
                            "label": "发送五高问卷",
                        }
                    ],
                },
                {
                    id: '60-1',
                    label: '常规问卷',
                    path: '/main/evaluate/common',
                    children: [
                        {
                            "id": "60-1-4",
                            "children": [],
                            "name": "查询",
                            "permission": "common_search",
                            "label": "查询",
                        },
                        {
                            "id": "60-1-1",
                            "children": [],
                            "name": "添加",
                            "permission": "common_add",
                            "label": "添加"
                        },
                        {
                            "id": "60-1-2",
                            "children": [],
                            "name": "编辑",
                            "permission": "common_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "60-1-3",
                            "children": [],
                            "name": "删除",
                            "permission": "common_del",
                            "label": "删除",
                        }
                    ],
                },
                {
                    id: '60-2',
                    label: '健康初筛',
                    path: '/main/evaluate/chushai',
                    children: [
                        {
                            "id": "60-2-4",
                            "children": [],
                            "name": "查询",
                            "permission": "chushai_search",
                            "label": "查询",
                        },
                        {
                            "id": "60-2-1",
                            "children": [],
                            "name": "添加",
                            "permission": "chushai_add",
                            "label": "添加"
                        },
                        {
                            "id": "60-2-2",
                            "children": [],
                            "name": "编辑",
                            "permission": "chushai_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "60-2-3",
                            "children": [],
                            "name": "删除",
                            "permission": "chushai_del",
                            "label": "删除",
                        }
                    ],
                },
                {
                    id: '60-3',
                    label: 'SLC90心理评测',
                    path: '/main/evaluate/slc90',
                    children: [
                        {
                            "id": "60-3-4",
                            "children": [],
                            "name": "查询",
                            "permission": "slc90_search",
                            "label": "查询",
                        },
                        {
                            "id": "60-3-1",
                            "children": [],
                            "name": "添加",
                            "permission": "slc90_add",
                            "label": "添加"
                        },
                        {
                            "id": "60-3-2",
                            "children": [],
                            "name": "编辑",
                            "permission": "slc90_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "60-3-3",
                            "children": [],
                            "name": "删除",
                            "permission": "slc90_del",
                            "label": "删除",
                        }
                    ],
                },
                {
                    id: '60-4',
                    label: '自定义问卷',
                    path: '/main/evaluate/custom',
                    children: [
                        {
                            "id": "60-4-4",
                            "children": [],
                            "name": "查询",
                            "permission": "custom_search",
                            "label": "查询",
                        },
                        {
                            "id": "60-4-1",
                            "children": [],
                            "name": "添加",
                            "permission": "custom_add",
                            "label": "添加"
                        },
                        {
                            "id": "60-4-2",
                            "children": [],
                            "name": "编辑",
                            "permission": "custom_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "60-4-3",
                            "children": [],
                            "name": "删除",
                            "permission": "custom_del",
                            "label": "删除",
                        }
                    ],
                },
            ]
        },
        {
            id: '70',
            label: '健康小屋',
            path: '/main/hut',
            icon: 'el-icon-s-opportunity',
            children: [
                {
                    id: '70-1',
                    label: '报告管理',
                    path: '/main/hut/report',
                    children: [
                        {
                            "id": "70-1-3",
                            "children": [],
                            "name": "查询",
                            "permission": "report_search",
                            "label": "查询",
                        },
                        {
                            "id": "70-1-1",
                            "children": [],
                            "name": "编辑",
                            "permission": "report_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "70-1-2",
                            "children": [],
                            "name": "删除",
                            "permission": "report_del",
                            "label": "删除",
                        }
                    ],
                },
                {
                    id: '70-2',
                    label: '危急值监控管理',
                    path: '/main/hut/weijizhi',
                    children: [
                        {
                            "id": "70-2-5",
                            "children": [],
                            "name": "查询",
                            "permission": "weijizhi_search",
                            "label": "查询",
                        },
                        {
                            "id": "70-2-1",
                            "children": [],
                            "name": "查看报告",
                            "permission": "weijizhi_view",
                            "label": "查看报告",
                        },
                        {
                            "id": "70-2-2",
                            "children": [],
                            "name": "微信通知",
                            "permission": "weijizhi_wx",
                            "label": "微信通知",
                        },
                        {
                            "id": "70-2-3",
                            "children": [],
                            "name": "处理",
                            "permission": "weijizhi_cl",
                            "label": "处理",
                        },
                        {
                            "id": "70-2-4",
                            "children": [],
                            "name": "备注",
                            "permission": "weijizhi_bz",
                            "label": "备注",
                        }
                    ],
                },
                {
                    id: '70-3',
                    label: '单项测量',
                    path: '/main/hut/dxcl',
                    children: [
                        {
                            "id": "70-3-2",
                            "children": [],
                            "name": "查询",
                            "permission": "dxcl_search",
                            "label": "查询",
                        },
                        {
                            "id": "70-3-1",
                            "children": [],
                            "name": "查看详情",
                            "permission": "dxcl_view",
                            "label": "查看详情",
                        }
                    ],
                }, {
                    id: '70-4',
                    label: '小屋清单列表',
                    path: '/main/hut/hut',
                    children: [
                        {
                            "id": "70-4-3",
                            "children": [],
                            "name": "查询",
                            "permission": "hut_search",
                            "label": "查询",
                        },
                        {
                            "id": "70-4-1",
                            "children": [],
                            "name": "编辑",
                            "permission": "hut_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "70-4-2",
                            "children": [],
                            "name": "删除",
                            "permission": "hut_del",
                            "label": "删除",
                        }
                    ],
                },
                {
                    id: '70-5',
                    label: '设备清单列表',
                    path: '/main/hut/device',
                    children: []
                }
            ]
        },
        {
            id: '80',
            label: '随访管理',
            path: '/main/follow-up',
            icon: 'el-icon-service',
            children: [
                {
                    id: '80-1',
                    label: '随访任务',
                    path: '/main/follow-up/task',
                },
                {
                    id: '80-2',
                    label: '随访记录',
                    path: '/main/follow-up/log',
                },
            ]
        },
        {
            id: '90',
            label: '阳性跟踪',
            path: '/main/positive',
            icon: 'el-icon-odometer',
            children: [
                {
                    id: '90-1',
                    label: '首次跟踪',
                    path: '/main/positive/new',
                },
                {
                    id: '90-2',
                    label: '跟踪计划',
                    path: '/main/positive/task',
                },
                {
                    id: '90-3',
                    label: '跟踪记录',
                    path: '/main/positive/log',
                },
                {
                    id: '90-4',
                    label: '预警配置',
                    path: '/main/positive/setting',
                },
            ]
        },
        {
            id: '100',
            label: '膳食管理',
            path: '/main/diet',
            icon: 'el-icon-food',
            children: [
                {
                    id: '100-1',
                    label: '膳食方案',
                    path: '/main/diet/task',
                },
                {
                    id: '100-2',
                    label: '膳食原则',
                    path: '/main/diet/principles',
                },
                {
                    id: '100-3',
                    label: '中医食疗',
                    path: '/main/diet/dtcm',
                },
                {
                    id: '100-4',
                    label: '菜谱模板',
                    path: '/main/diet/cookbook',
                },
                {
                    id: '100-5',
                    label: '原料库',
                    path: '/main/diet/ingredients',
                },
                {
                    id: '100-6',
                    label: '成品菜',
                    path: '/main/diet/dishes',
                },
            ]
        },
        {
            id: '110',
            label: '运动管理',
            path: '/main/fitness',
            icon: 'el-icon-basketball',
            children: [
                {
                    id: '110-1',
                    label: '运动方案',
                    path: '/main/fitness/task',
                },
                {
                    id: '110-2',
                    label: '运动原则',
                    path: '/main/fitness/principles',
                },
                {
                    id: '110-3',
                    label: '运动方案模板',
                    path: '/main/fitness/template',
                },
                {
                    id: '110-4',
                    label: '运动项目',
                    path: '/main/fitness/items',
                },
            ]
        },
        {
            id: '120',
            label: '系统首页',
            path: '/company/dashboard',
            icon: 'el-icon-s-home',
        },
        {
            id: '130',
            label: '管理员管理',
            path: '/company/user',
            icon: 'el-icon-user',
            children: [
                {
                    "id": "130-1",
                    "children": [],
                    "name": "新建管理员",
                    "permission": "create_admin",
                    "label": "新建管理员",
                },
                {
                    "id": "130-2",
                    "children": [],
                    "name": "编辑",
                    "permission": "admin_edit",
                    "label": "编辑",
                },
                {
                    "id": "130-3",
                    "children": [],
                    "name": "重置密码",
                    "permission": "admin_repasswd",
                    "label": "重置密码",
                },
                {
                    "id": "130-4",
                    "children": [],
                    "name": "查询",
                    "permission": "admin_check",
                    "label": "查询",
                },
            ],
        },
        {
            id: '140',
            label: '专家库信息',
            path: '/company/expert',
            icon: 'el-icon-s-custom',
            children: [
                {
                    "id": "140-4",
                    "children": [],
                    "name": "查询",
                    "permission": "expert_search",
                    "label": "查询",
                },
                {
                    "id": "140-1",
                    "children": [],
                    "name": "新建",
                    "permission": "create_expert",
                    "label": "新建",
                },
                {
                    "id": "140-2",
                    "children": [],
                    "name": "编辑",
                    "permission": "expert_edit",
                    "label": "编辑",
                },
                {
                    "id": "140-3",
                    "children": [],
                    "name": "删除",
                    "permission": "expert_del",
                    "label": "删除",
                }
            ],
        },
        {
            id: '150',
            label: '人员管理',
            path: '/company/account',
            icon: 'el-icon-user-solid',
            children: [
                {
                    id: '150-1',
                    label: '人员清单',
                    path: '/company/account',
                    icon: 'el-icon-user-solid',
                    "children": [
                        {
                            "id": "150-1-1",
                            "children": [],
                            "name": "添加人员",
                            "permission": "add_person",
                            "label": "添加人员",
                        },
                        {
                            "id": "150-1-2",
                            "children": [],
                            "name": "批量导入",
                            "permission": "batch_import_person",
                            "label": "批量导入",
                        },
                        {
                            "id": "150-1-6",
                            "children": [],
                            "name": "批量导出",
                            "permission": "batch_import_download",
                            "label": "批量导出",
                        },
                        {
                            "id": "150-1-3",
                            "children": [],
                            "name": "编辑",
                            "permission": "inventory_edit",
                            "label": "编辑",
                        },
                        {
                            "id": "150-1-4",
                            "children": [],
                            "name": "删除",
                            "permission": "inventory_delete",
                            "label": "删除",
                        },
                        {
                            "id": "150-1-5",
                            "children": [],
                            "name": "查询",
                            "permission": "inventory_check",
                            "label": "查询",
                        },
                    ],
                },
                {
                    id: '150-2',
                    label: '人员变更',
                    path: '/company/account/transfer',
                    icon: 'el-icon-refresh',
                    "children": [
                        {
                            "id": "150-2-1",
                            "children": [],
                            "name": "申请变更",
                            "permission": "transfer_apply",
                            "label": "申请变更",
                        },
                        {
                            "id": "150-2-2",
                            "children": [],
                            "name": "查询",
                            "permission": "transfer_check",
                            "label": "查询",
                        },
                    ],
                },
            ]
        },
        {
            id: '160',
            label: '单位管理',
            path: '/company/company',
            icon: 'el-icon-office-building',
            children: [
                {
                    "id": "160-1",
                    "children": [],
                    "name": "新建单位",
                    "permission": "create_company",
                    "label": "新建单位",
                },
                {
                    "id": "160-2",
                    "children": [],
                    "name": "编辑单位",
                    "permission": "edit_company",
                    "label": "编辑单位",
                },
                {
                    "id": "160-3",
                    "children": [],
                    "name": "添加下级单位",
                    "permission": "add_subordinate_company",
                    "label": "添加下级单位",
                },
                {
                    "id": "160-4",
                    "children": [],
                    "name": "删除单位",
                    "permission": "delete_company",
                    "label": "删除单位",
                },
                {
                    "id": "160-5",
                    "children": [],
                    "name": "查询",
                    "permission": "company_check",
                    "label": "查询",
                },
            ],
        },
        {
            id: '170',
            label: '健康设施',
            path: '/company/facility',
            icon: 'el-icon-setting',
            children: [
                {
                    "id": "170-1",
                    "children": [],
                    "name": "查询",
                    "permission": "facility_search",
                    "label": "查询",
                },
                {
                    "id": "170-2",
                    "children": [],
                    "name": "新建",
                    "permission": "create_facility",
                    "label": "新建",
                },
                {
                    "id": "170-3",
                    "children": [],
                    "name": "编辑",
                    "permission": "facility_edit",
                    "label": "编辑",
                },
                {
                    "id": "170-4",
                    "children": [],
                    "name": "导出",
                    "permission": "facility_export",
                    "label": "导出",
                }
            ],
        },
        {
            id: '180',
            label: '角色管理',
            path: '/company/role',
            icon: 'el-icon-s-operation',
            children: [
                {
                    "id": "180-1",
                    "children": [],
                    "name": "新建",
                    "permission": "role_add",
                    "label": "新建",
                },
                {
                    "id": "180-2",
                    "children": [],
                    "name": "修改",
                    "permission": "role_edit",
                    "label": "修改",
                },
                {
                    "id": "180-3",
                    "children": [],
                    "name": "设置权限",
                    "permission": "update_quanx",
                    "label": "设置权限",
                },
                {
                    "id": "180-4",
                    "children": [],
                    "name": "删除",
                    "permission": "role_delete",
                    "label": "删除",
                },
                {
                    "id": "180-5",
                    "children": [],
                    "name": "查询",
                    "permission": "role_check",
                    "label": "查询",
                },
            ],
        },
        {
            id: '200',
            label: '健康包保管理',
            path: '/company/healthInsurance',
            icon: 'el-icon-folder-add',
            children: [
                {
                    "id": "200-1",
                    "children": [],
                    "name": "修改",
                    "permission": "hie_edit",
                    "label": "修改",
                },
                {
                    "id": "200-2",
                    "children": [],
                    "name": "删除",
                    "permission": "hie_del",
                    "label": "删除",
                },
                {
                    "id": "200-3",
                    "children": [],
                    "name": "详情",
                    "permission": "hie_info",
                    "label": "详情",
                },
                {
                    "id": "200-4",
                    "children": [],
                    "name": "查询",
                    "permission": "hie_search",
                    "label": "查询",
                },
            ],
        },
        {
            id: '210',
            label: '大厅管理',
            path: '/company/hall',
            icon: 'el-icon-folder-add',
            children: [
                {
                    id: '210-1',
                    label: '医生排班',
                    path: '/company/hall/scheduling',
                    icon: 'el-icon-user-solid',
                },
                {
                    id: '210-2',
                    label: '工作量',
                    path: '/company/hall/workload',
                    icon: 'el-icon-refresh',
                },
            ]
        },
        {
            id: '220',
            label: '健康宣教',
            path: '/company/jkxj',
            icon: 'el-icon-folder-add',
            children: [
                {
                    id: '220-1',
                    label: '健康宣教',
                    path: '/company/jkxj/data',
                    icon: 'el-icon-user-solid',
                },
            ]
        },
        {
            id: '230',
            label: '健康达人',
            path: '/company/jkdr',
            icon: 'el-icon-folder-add',
            children: [
                {
                    id: '230-1',
                    label: '健康达人',
                    path: '/company/jkdr/data',
                    icon: 'el-icon-user-solid',
                },
                {
                    id: '230-2',
                    label: '汇总统计',
                    path: '/company/jkdr/statistics',
                    icon: 'el-icon-user-solid',
                },
            ]
        },
        {
            id: '190',
            label: '操作日志',
            path: '/main/oplog',
            icon: 'el-icon-notebook-1',
        }
    ],
    quanxMenu:[
        {
            "id": "1688513283646308354",
            "parentId": "-1",
            "children": [],
            "icon": "s-icon_home",
            "name": "首页",
            "spread": false,
            "path": "/anotherHome/index",
            "keepAlive": "0",
            "permission": null,
            "type": "0",
            "label": "首页",
            "sort": 0,
            "hasChildren": true,
            "allocationStatus": 1
        },
        {
            "id": "1200",
            "parentId": "-1",
            "children": [],
            "icon": "s-icon_role",
            "name": "角色管理",
            "spread": false,
            "path": "/Admin/Role/index",
            "keepAlive": "1",
            "permission": null,
            "type": "0",
            "label": "角色管理",
            "sort": 11,
            "hasChildren": true,
            "allocationStatus": 0
        },
        {
            "id": "1676258272843087874",
            "parentId": "-1",
            "children": [
                {
                    "id": "1676258396113682434",
                    "parentId": "1676258272843087874",
                    "children": [],
                    "icon": null,
                    "name": "新建管理员",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "create_admin",
                    "type": "1",
                    "label": "新建管理员",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                },
                {
                    "id": "1676258479211233282",
                    "parentId": "1676258272843087874",
                    "children": [],
                    "icon": null,
                    "name": "编辑",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "admin_edit",
                    "type": "1",
                    "label": "编辑",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                },
                {
                    "id": "1676258553886621697",
                    "parentId": "1676258272843087874",
                    "children": [],
                    "icon": null,
                    "name": "删除",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "admin_delete",
                    "type": "1",
                    "label": "删除",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                }
            ],
            "icon": "s-icon_management",
            "name": "管理员管理",
            "spread": false,
            "path": "/unitEnterprise/administratorsManagement/index",
            "keepAlive": "1",
            "permission": null,
            "type": "0",
            "label": "管理员管理",
            "sort": 10,
            "hasChildren": true,
            "allocationStatus": 1
        },
        {
            "id": "1676257174908518401",
            "parentId": "-1",
            "children": [
                {
                    "id": "1676257270962274306",
                    "parentId": "1676257174908518401",
                    "children": [],
                    "icon": null,
                    "name": "新建单位",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "create_company",
                    "type": "1",
                    "label": "新建单位",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                },
                {
                    "id": "1676257356043730946",
                    "parentId": "1676257174908518401",
                    "children": [],
                    "icon": null,
                    "name": "编辑单位",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "edit_company",
                    "type": "1",
                    "label": "编辑单位",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                },
                {
                    "id": "1676257574172704769",
                    "parentId": "1676257174908518401",
                    "children": [],
                    "icon": null,
                    "name": "添加下级单位",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "add_subordinate_company",
                    "type": "1",
                    "label": "添加下级单位",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                },
                // {
                //     "id": "1676257657383501826",
                //     "parentId": "1676257174908518401",
                //     "children": [],
                //     "icon": null,
                //     "name": "下载二维码",
                //     "spread": false,
                //     "path": null,
                //     "keepAlive": "0",
                //     "permission": "download_code",
                //     "type": "1",
                //     "label": "下载二维码",
                //     "sort": 999,
                //     "hasChildren": true,
                //     "allocationStatus": 1
                // },
                // {
                //     "id": "1676257723137605634",
                //     "parentId": "1676257174908518401",
                //     "children": [],
                //     "icon": null,
                //     "name": "关闭二维码",
                //     "spread": false,
                //     "path": null,
                //     "keepAlive": "0",
                //     "permission": "turn_off_code",
                //     "type": "1",
                //     "label": "关闭二维码",
                //     "sort": 999,
                //     "hasChildren": true,
                //     "allocationStatus": 1
                // },
                // {
                //     "id": "1676257842109038593",
                //     "parentId": "1676257174908518401",
                //     "children": [],
                //     "icon": null,
                //     "name": "启用二维码",
                //     "spread": false,
                //     "path": null,
                //     "keepAlive": "0",
                //     "permission": "turn_on_code",
                //     "type": "1",
                //     "label": "启用二维码",
                //     "sort": 999,
                //     "hasChildren": true,
                //     "allocationStatus": 1
                // },
                {
                    "id": "1687285598794178561",
                    "parentId": "1676257174908518401",
                    "children": [],
                    "icon": null,
                    "name": "删除单位",
                    "spread": false,
                    "path": null,
                    "keepAlive": "0",
                    "permission": "delete_unit",
                    "type": "1",
                    "label": "删除单位",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                }
            ],
            "icon": "el-icon-office-building",
            "name": "单位管理",
            "spread": false,
            "path": "/unitEnterprise/unitManagement/index",
            "keepAlive": "0",
            "permission": null,
            "type": "0",
            "label": "单位管理",
            "sort": 9,
            "hasChildren": true,
            "allocationStatus": 1
        },
        {
            "id": "1676258652318547970",
            "parentId": "-1",
            "children": [
                {
                    "id": "1676258761282371585",
                    "parentId": "1676258652318547970",
                    "children": [
                        {
                            "id": "1676258919076282370",
                            "parentId": "1676258761282371585",
                            "children": [],
                            "icon": null,
                            "name": "添加人员",
                            "spread": false,
                            "path": null,
                            "keepAlive": "0",
                            "permission": "add_person",
                            "type": "1",
                            "label": "添加人员",
                            "sort": 999,
                            "hasChildren": true,
                            "allocationStatus": 1
                        },
                        {
                            "id": "1676258994544394242",
                            "parentId": "1676258761282371585",
                            "children": [],
                            "icon": null,
                            "name": "批量导入",
                            "spread": false,
                            "path": null,
                            "keepAlive": "0",
                            "permission": "batch_import_person",
                            "type": "1",
                            "label": "批量导入",
                            "sort": 999,
                            "hasChildren": true,
                            "allocationStatus": 1
                        },
                        {
                            "id": "1676259069366583297",
                            "parentId": "1676258761282371585",
                            "children": [],
                            "icon": null,
                            "name": "编辑",
                            "spread": false,
                            "path": null,
                            "keepAlive": "0",
                            "permission": "inventory_edit",
                            "type": "1",
                            "label": "编辑",
                            "sort": 999,
                            "hasChildren": true,
                            "allocationStatus": 1
                        },
                        {
                            "id": "1676259141324062722",
                            "parentId": "1676258761282371585",
                            "children": [],
                            "icon": null,
                            "name": "删除",
                            "spread": false,
                            "path": null,
                            "keepAlive": "0",
                            "permission": "inventory_delete",
                            "type": "1",
                            "label": "删除",
                            "sort": 999,
                            "hasChildren": true,
                            "allocationStatus": 1
                        },
                        {
                            "id": "1676259219895959553",
                            "parentId": "1676258761282371585",
                            "children": [],
                            "icon": null,
                            "name": "查询",
                            "spread": false,
                            "path": null,
                            "keepAlive": "0",
                            "permission": "inventory_check",
                            "type": "1",
                            "label": "查询",
                            "sort": 999,
                            "hasChildren": true,
                            "allocationStatus": 1
                        },
                        {
                            "id": "1682418296526077953",
                            "parentId": "1676258761282371585",
                            "children": [],
                            "icon": null,
                            "name": "批量变更单位",
                            "spread": false,
                            "path": null,
                            "keepAlive": "0",
                            "permission": "change_unit",
                            "type": "1",
                            "label": "批量变更单位",
                            "sort": 999,
                            "hasChildren": true,
                            "allocationStatus": 1
                        }
                    ],
                    "icon": null,
                    "name": "人员清单",
                    "spread": false,
                    "path": "/unitEnterprise/peopleManagement/peopleList/index",
                    "keepAlive": "1",
                    "permission": null,
                    "type": "0",
                    "label": "人员清单",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                },
                {
                    "id": "1676258834477170690",
                    "parentId": "1676258652318547970",
                    "children": [],
                    "icon": null,
                    "name": "人员登记",
                    "spread": false,
                    "path": "/unitEnterprise/peopleManagement/personnelRegister/index",
                    "keepAlive": "1",
                    "permission": null,
                    "type": "0",
                    "label": "人员登记",
                    "sort": 999,
                    "hasChildren": true,
                    "allocationStatus": 1
                }
            ],
            "icon": "s-icon_person",
            "name": "人员管理",
            "spread": false,
            "path": "/unitEnterprise/peopleManagement",
            "keepAlive": "0",
            "permission": null,
            "type": "0",
            "label": "人员管理",
            "sort": 8,
            "hasChildren": true,
            "allocationStatus": 1
        },
        // {
        //     "id": "1676258083793223682",
        //     "parentId": "-1",
        //     "children": [
        //         {
        //             "id": "1690949675467026434",
        //             "parentId": "1676258083793223682",
        //             "children": [],
        //             "icon": null,
        //             "name": "添加员工",
        //             "spread": false,
        //             "path": null,
        //             "keepAlive": "0",
        //             "permission": "add_employee",
        //             "type": "1",
        //             "label": "添加员工",
        //             "sort": 999,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1690975081742086145",
        //             "parentId": "1676258083793223682",
        //             "children": [],
        //             "icon": null,
        //             "name": "查询",
        //             "spread": false,
        //             "path": null,
        //             "keepAlive": "0",
        //             "permission": "ervices_query",
        //             "type": "1",
        //             "label": "查询",
        //             "sort": 999,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1693498976357462017",
        //             "parentId": "1676258083793223682",
        //             "children": [],
        //             "icon": null,
        //             "name": "批量移出",
        //             "spread": false,
        //             "path": null,
        //             "keepAlive": "0",
        //             "permission": "batch_remove_person",
        //             "type": "1",
        //             "label": "批量移出",
        //             "sort": 999,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1732667455648301057",
        //             "parentId": "1676258083793223682",
        //             "children": [],
        //             "icon": null,
        //             "name": "移出",
        //             "spread": false,
        //             "path": "",
        //             "keepAlive": "0",
        //             "permission": "remove_employee",
        //             "type": "1",
        //             "label": "移出",
        //             "sort": 999,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         }
        //     ],
        //     "icon": "s-icon_service",
        //     "name": "健康服务管理",
        //     "spread": false,
        //     "path": "/unitEnterprise/HealthServiceManagement/index",
        //     "keepAlive": "1",
        //     "permission": null,
        //     "type": "0",
        //     "label": "健康服务管理",
        //     "sort": 7,
        //     "hasChildren": true,
        //     "allocationStatus": 1
        // },
        {
            "id": "1682206412782190594",
            "parentId": "-1",
            "children": [],
            "icon": "s-icon_expert_database",
            "name": "专家信息库",
            "spread": false,
            "path": "/unitEnterprise/ExpertDatabase/index",
            "keepAlive": "0",
            "permission": null,
            "type": "0",
            "label": "专家信息库",
            "sort": 7,
            "hasChildren": true,
            "allocationStatus": 1
        },
        // {
        //     "id": "1684084918457618433",
        //     "parentId": "-1",
        //     "children": [],
        //     "icon": "icon_telemedicine",
        //     "name": "远程会诊",
        //     "spread": false,
        //     "path": "/unitEnterprise/telemedicine/index",
        //     "keepAlive": "0",
        //     "permission": null,
        //     "type": "0",
        //     "label": "远程会诊",
        //     "sort": 6,
        //     "hasChildren": true,
        //     "allocationStatus": 1
        // },
        // {
        //     "id": "1686341150115389441",
        //     "parentId": "-1",
        //     "children": [],
        //     "icon": "s-icon_assistance",
        //     "name": "就医协助",
        //     "spread": false,
        //     "path": "/unitEnterprise/assistance/index",
        //     "keepAlive": "0",
        //     "permission": null,
        //     "type": "0",
        //     "label": "就医协助",
        //     "sort": 5,
        //     "hasChildren": true,
        //     "allocationStatus": 1
        // },
        // {
        //     "id": "1684092260263809026",
        //     "parentId": "-1",
        //     "children": [
        //         {
        //             "id": "1684521018372284418",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": "",
        //             "name": "所属单位数据分析",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/DataAnalysisOfAffiliatedUnits/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "所属单位数据分析",
        //             "sort": 7,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1684520875875000321",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": "",
        //             "name": "健康小屋数据中心",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/HealthCabinDataCenter/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "健康小屋数据中心",
        //             "sort": 6,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1684096777084620801",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": null,
        //             "name": "医疗服务中心数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/MedicalServiceCenterData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "医疗服务中心数据",
        //             "sort": 5,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1684096684981899265",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": "",
        //             "name": "就医协助数据中心",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/MedicalAssistanceDataCenter/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "就医协助数据中心",
        //             "sort": 4,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1684096558490079234",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": null,
        //             "name": "健康管理数据中心",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/HealthManagementDataCenter/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "健康管理数据中心",
        //             "sort": 3,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1684096508267483137",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": null,
        //             "name": "危急值监控数据中心",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/CriticalValueMonitoringDataCenter/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "危急值监控数据中心",
        //             "sort": 2,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1684096399253327873",
        //             "parentId": "1684092260263809026",
        //             "children": [],
        //             "icon": null,
        //             "name": "医疗救护保障支持",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataCenter/MedicalRescueSupport/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "医疗救护保障支持",
        //             "sort": 1,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         }
        //     ],
        //     "icon": "s-icon_data_center",
        //     "name": "数据中心",
        //     "spread": false,
        //     "path": "/unitEnterprise/DataCenter",
        //     "keepAlive": "0",
        //     "permission": null,
        //     "type": "0",
        //     "label": "数据中心",
        //     "sort": 4,
        //     "hasChildren": true,
        //     "allocationStatus": 1
        // },
        // {
        //     "id": "1688741246195347458",
        //     "parentId": "-1",
        //     "children": [
        //         {
        //             "id": "1688742833512919041",
        //             "parentId": "1688741246195347458",
        //             "children": [],
        //             "icon": null,
        //             "name": "心理评估数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataBoard/PsychologicalEvaluationData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "心理评估数据",
        //             "sort": 6,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1688742767431659521",
        //             "parentId": "1688741246195347458",
        //             "children": [],
        //             "icon": null,
        //             "name": "健康风险评估数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataBoard/HealthExaminationEvaluationData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "健康风险评估数据",
        //             "sort": 5,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1688742696564699137",
        //             "parentId": "1688741246195347458",
        //             "children": [],
        //             "icon": null,
        //             "name": "年度体检数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataBoard/AnnualPhysicalExaminationData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "年度体检数据",
        //             "sort": 4,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1688742630160478209",
        //             "parentId": "1688741246195347458",
        //             "children": [],
        //             "icon": null,
        //             "name": "小屋数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataBoard/CabinData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "小屋数据",
        //             "sort": 3,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1688742551429197826",
        //             "parentId": "1688741246195347458",
        //             "children": [],
        //             "icon": null,
        //             "name": "建档数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataBoard/ArchivalData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "建档数据",
        //             "sort": 2,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         },
        //         {
        //             "id": "1688741507873779714",
        //             "parentId": "1688741246195347458",
        //             "children": [],
        //             "icon": null,
        //             "name": "激活数据",
        //             "spread": false,
        //             "path": "/unitEnterprise/DataBoard/ActivateData/index",
        //             "keepAlive": "0",
        //             "permission": null,
        //             "type": "0",
        //             "label": "激活数据",
        //             "sort": 1,
        //             "hasChildren": true,
        //             "allocationStatus": 1
        //         }
        //     ],
        //     "icon": "s-icon_data_board",
        //     "name": "数据报表",
        //     "spread": false,
        //     "path": "/unitEnterprise/DataBoard",
        //     "keepAlive": "0",
        //     "permission": null,
        //     "type": "0",
        //     "label": "数据报表",
        //     "sort": 3,
        //     "hasChildren": true,
        //     "allocationStatus": 1
        // },
        // {
        //     "id": "1676257078582132737",
        //     "parentId": "-1",
        //     "children": [],
        //     "icon": "s-icon_health_record",
        //     "name": "健康档案管理",
        //     "spread": false,
        //     "path": "/unitEnterprise/HealthRecordManagement/index",
        //     "keepAlive": "1",
        //     "permission": null,
        //     "type": "0",
        //     "label": "健康档案管理",
        //     "sort": 2,
        //     "hasChildren": true,
        //     "allocationStatus": 1
        // },

    ]
}

export default data
