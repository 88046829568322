import Vue from 'vue';
import VueClipBoard from 'vue-clipboard2'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import http from "./http"
import './prototype'
import './echarts'
import './user'
import '../components'
import Print from 'vue-print-nb'
// import has from './user'
// Vue.use(Print)
// import Print from './print'
Vue.use(Print)
Vue.use(VueClipBoard)
Vue.use(ElementUI);
Vue.filter('datetime', function (value) {
    if (value) {
        let date = new Date(value)
        return date.format('yyyy-MM-dd hh:mm:ss')
    } else {
        return value
    }
})
Vue.config.productionTip = false
Vue.prototype.$http = http
