<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="getData" icon="el-icon-refresh" class="hidden-sm-only hidden-sm-only">刷新
                </el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="单项测量" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="工作单位" label-width="70px">
                    <cascader-company-name v-model="search.companyName"></cascader-company-name>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="search.name" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="search.phoneNumber" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item label="小屋名称">
                    <el-select v-model="search.healthHutName" placeholder="请选择" style="width:100%" filterable allow-create
                               default-first-option clearable>
                        <el-option :label="item.healthHutName" :value="item.healthHutName" v-for="(item, index) in hutList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报告时间">
                    <el-date-picker v-model="search.daterange" size="small" type="daterange" value-format="yyyy-MM-dd"
                        range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search"
                        v-has="'dxcl_search'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%" border>
                    <!-- <el-table-column type="selection" width="55" align="center" /> -->
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                        width="55" />
                    <el-table-column prop="examTime" label="指标上传时间" width="" show-overflow-tooltip />
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.patientName }}</span>
                                <span v-if="scope.row.gender == '2'"> 女 </span>
                                <span v-if="scope.row.gender == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                            <div><small> {{ maskIdCardRegex(scope.row.idCard || "") }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                    @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phoneNumber" label="手机号" width="" show-overflow-tooltip />
                    <el-table-column prop="healthHutName" label="小屋名称" width="" show-overflow-tooltip />
                    <el-table-column prop="deviceName" label="设备名称" width="" show-overflow-tooltip />
                    <el-table-column prop="deviceTypeName" label="设备类型" width="" show-overflow-tooltip />
                    <el-table-column prop="itemName" label="测量项目" width="" show-overflow-tooltip />
                    <el-table-column prop="itemValue" label="实际值" width="" show-overflow-tooltip />
                    <el-table-column align="center" width="" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleUpdate(scope.row)" v-has="'dxcl_view'">
                                查 看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
            ref="vmodel"></data-form>
    </div>
</template>

<script>
import dataForm from './widgets/dataform'

export default {
    name: 'weijizhi',
    path: '/main/xiaowu/weijizhi',
    components: {
        dataForm,
    },
    data() {
        return {
            loading: false,
            search: {},
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            },
            dialogVisible: false,
            form: {},
            isFullIdCard: [],
            hutList: []
        }
    },
    methods: {
        async getHutData() {
            try {
                this.$http.get(`/hut/hut?page=1&pageSize=500`)
                    .then(resp => {
                        this.hutList = resp.data.data
                    }).catch(err => {
                    console.log(err)
                    this.$message.error(err.message)
                })
            } catch (error) {
                this.$message.error(error.message)
                this.loading = false
            }
        },
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index) {
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        handleUpdate(row) {
            this.form = row
            this.dialogVisible = true
        },
        async getData() {
            this.dialogVisible = false
            this.loading = true
            try {
                this.$http.get(`/hut/dxcl?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                    .then(resp => {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                        this.loading = false
                    }).catch(err => {
                        console.log(err)
                        this.$message.error(err.message)
                        this.loading = false
                    })
            } catch (error) {
                this.$message.error(error.message)
                this.loading = false
            }
        }
    },
    mounted() {
        this.getHutData()
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 5px !important;
        margin-bottom: 5px !important;
    }

    .btns {
        float: right;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
