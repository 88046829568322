<template>
    <div class="page">
        <el-row :gutter="20">
            <el-col :span="24" style="position: relative;">
                <userForm :form="form"></userForm>
            </el-col>
            <!-- <el-col :span="14">
                <div class="user-title">用户事件</div>
                <div class="search">
                    <el-form :inline="true" :model="search" size="small">
                        <el-form-item label="类型">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain @click="getData">查询</el-button>
                            <el-button type="" plain @click="getData">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="main-content">
                    <div class="table-content">
                        <el-table :data="table.data" border stripe size="mini" v-loading="loading"
                                  style="width: 100%;cursor: pointer" height="calc(100vh - 440px)"
                                  :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                            <el-table-column type="index" label="序号" align="center"
                                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                             width="55"/>
                            <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field"
                                             :label="v.title" :width="v.width"
                                             :formatter="v.formatter" show-overflow-tooltip :key="idx">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pager" v-if="table.total > table.pageSize">
                    <el-pagination layout="total, prev, pager, next, sizes"
                                   :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                                   :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                                   @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
                </div>
            </el-col> -->
        </el-row>

    </div>
</template>

<script>
    import data from '../../data'
    import userForm from '../../../../components/user/userInfo'

    export default {
        props: {
            form: {
                default: {
                    JBXX:{}
                }
            },
        },
        components: {
            userForm
        },
        data() {
            return {
                loading: false,
                search: {},
                table: data.baseinfo,
            }
        },
        methods: {
            getData(){}
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .user-title {
        padding: 10px 0;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }


    .pager {
        padding-top: 10px;
        text-align: center;
    }

</style>
