<template>
    <div class="page">
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%"
                    height="calc(100vh - 250px)" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                        width="55" />
                    <el-table-column align="center" label="基本信息" width="">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.sex == '2'"> 女 </span>
                                <span v-if="scope.row.sex == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="客户标签" width="">
                        <template slot-scope="scope">
                            <popover-JCXM :tagList="scope.row"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="联系方式" width="">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                            </div>
                            <div>{{ scope.row.mobile }}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="warning" size="mini"
                                @click="$router.push({ path: `/main/phr/detail/1` })">查看详情
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['date'],
    data() {
        return {
            loading: false,
            search: { status: '未开始' },
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 15
            }
        }
    },
    methods: {
        dateFormatter(v) {
            return new Date(v.project.startTime).format('yyyy-MM-dd')
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.post(`/healthProgress`, {
                "key": "name",
                "value": "",
                "companyName": "",
                "questionnaireTime": [
                    this.date,
                    this.date,
                ],
                "risklevelList": [],
                "level": "",
                "risklevel": "",
                "page": 1,
                "pageSize": 20
            })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    },
    watch: {
        // 监控 form.report 的变化
        'date': function (newValue, oldValue) {
            this.getData()
        }
    },
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 5px !important;
    }
}

/*.main-content {*/
/*    flex: 1;*/
/*    position: relative;*/
/*}*/

/*.table-content {*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
