<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handleDownload" icon="el-icon-download" type="defalut" v-has="'batch_progress_download'">导出</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="健康档案"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-form-item label="工作单位">
                    <cascader-company-name v-model="search.companyName"></cascader-company-name>
                </el-form-item>
                <el-form-item label="激活状态">
                    <el-select v-model="search.activeStatus" placeholder="请选择" style="width:130px" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未激活" value="0"></el-option>
                        <el-option label="已激活" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问卷发送时间" label-width="100px">
                    <el-date-picker v-model="search.questionnaireTime" type="daterange" range-separator="至" clearable
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="建档状态">
                    <el-select v-model="search.healthCreateStatus" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未建档" value="0"></el-option>
                        <el-option label="已建档" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="回填状态">
                    <el-select v-model="search.questionnaireStatus" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="未发送" value="0"></el-option>
                        <el-option label="已发送未填写" value="1"></el-option>
                        <el-option label="已超时未填写" value="2"></el-option>
                        <el-option label="已填写" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索">
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:90px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="风险人群" label-width="70px">
                    <el-select v-model="search.risklevel" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="健康人群" value="健康人群"></el-option>
                        <el-option label="风险人群" value="风险人群"></el-option>
                        <el-option label="异常人群" value="异常人群"></el-option>
                        <el-option label="慢病人群" value="慢病人群"></el-option>
                        <el-option label="大病人群" value="大病人群"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人群等级" label-width="70px">
                    <el-select v-model="search.level" placeholder="请选择" style="width:100%" filterable allow-create
                               default-first-option clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in tagList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="指标等级" label-width="70px">
                    <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable
                                 v-model="search.risklevelList"></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'progress_search'">查询</el-button>
                    <el-button type="" plain @click="reset" v-has="'progress_search'">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="search" style="background-color: #fff">
            <div class="btns">
                <el-button type="primary" size="mini" @click="sendSMSAct" v-has="'progress_sms'"
                           :disabled="idList.length ? false : true">发送激活短信
                </el-button>
                <el-button type="primary" size="mini" @click="handleSendQuestionnaire"
                           :disabled="idList.length ? false : true" v-has="'progress_jcwj'">发送基础问卷
                </el-button>
                <el-button type="primary" size="mini" @click="handleRemind" :disabled="idList.length ? false : true" v-has="'progress_cb'">批量催办
                </el-button>
            </div>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="small" v-loading="loading" width="100%" height="100%" border
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center">
                    </el-table-column>
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="基本信息" width="160">
                        <template slot-scope="scope">
                            <div>
                                <span>{{ scope.row.name }}</span>
                                <span v-if="scope.row.sex == '2'"> 女 </span>
                                <span v-if="scope.row.sex == '1'"> 男 </span>
                                <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                            </div>
                            <div>
                                <small> {{ isFullIdCard[scope.$index] ? scope.row.idCard :
                                    maskIdCardRegex(scope.row.idCard) }}</small>
                                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                   @click="toggleIdCard(scope.$index)"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="客户标签" width="100">
                        <template slot-scope="scope">
                            <popover-JCXM :tagList="scope.row"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="联系方式" width="200">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                            </div>
                            <div>{{ scope.row.mobile }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="激活状态" width="" prop="activeStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.activeStatus == '1'" type="success">已激活</el-tag>
                            <el-tag v-else type="info">未激活</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="最近发送" width="140">
                        <template slot-scope="scope">
                            <div>{{ scope.row.questionnaireTime || '无' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="最近回填" prop="questionnaireStatus" width="130">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.questionnaireStatus === '3'" type="success">已填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaireStatus === '2'" type="warning">已超时未填写</el-tag>
                            <el-tag v-else-if="scope.row.questionnaireStatus === '1'" type="warning">已发送未填写</el-tag>
                            <el-tag v-else type="info">未发送</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="建档状态" width="140" prop="healthCreateStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.healthCreateStatus == '1'" type="success">已建档</el-tag>
                            <el-tag v-else type="info">未建档</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="建档时间" prop="healthCreateTime" width="140"
                                     :formatter="formatter.formatDateTime">
                    </el-table-column>
                    <el-table-column align="center" label="更新时间" width="140" prop="updateTime">
                    </el-table-column>
                    <el-table-column align="center" label="电话沟通" width="" prop="phoneStatus">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.phoneStatus == '1'" type="success">已沟通</el-tag>
                            <el-tag v-else type="info">未沟通</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="240" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowWenJuan(scope.row)" v-has="'progress_ckwj'">查看问卷</el-button>
                            <el-button type="text" size="mini" @click="handleRowDangan(scope.row)" v-has="'progress_sgjd'">手工档案</el-button>
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)" v-has="'progress_ckda'">查看档案</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="dataform"></data-form>
        <info-form :visible="infoVisible" :form="form" @close="infoVisible = false" @update="getData"
                   type="archives"></info-form>
        <el-dialog
                :visible.sync="dialogVisibleExport"
                title="提示"
                width="30%"
        >
            <p>导出的数据不能超过2万条，请缩小查询范围或导出当前范围内的前2万条数据。</p>
            <p>导出时请不要关闭页面。</p>
            <el-progress :percentage="progress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleExport = false" :disabled="but_disabled" v-loading="but_loading">取消</el-button>
                <el-button type="primary" @click="exportLimitedData" :disabled="but_disabled" v-loading="but_loading">导 出</el-button>
            </span>
        </el-dialog>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    import data from '../data'
    import formatter from "../../../data/formatter"
    import dataForm from "./widgets/dataForm";
    import {dbList} from "../../../data/utlisData"

    const makeInitSearch = () => {
        return {
            key: "name",
            value: "",
            companyName: "",
            questionnaireTime: [],
            risklevelList: [],
            level: "",
            risklevel: ""
        }
    }

    export default {
        name: 'phrProgress',
        components: {
            dataForm,
        },
        data() {
            return {
                but_loading: false,
                but_disabled: false,
                dialogVisibleExport: false,
                progress: 0, // 进度条的百分比
                dbList: dbList,
                defaultProps: {
                    multiple: true,
                    children: 'tags',
                    label: 'name',
                    value: 'value'
                },
                isFullIdCard: {},
                infoVisible: false,
                tagList: [
                    {
                        id: 3,
                        name: "三级"
                    },
                    {
                        id: 2,
                        name: "二级"
                    },
                    {
                        id: 1,
                        name: "一级"
                    },
                ],
                form: {},
                dialogVisible: false,
                formatter: formatter,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                idList: [],
                familyHistory: {
                    "fater": {"value": [], "remark": ""}, "monter": {"value": [], "remark": ""},
                    "brothers": {"value": [], "remark": ""}, "children": {"value": [], "remark": ""},
                    "familyDiseases": {"value": [], "remark": ""}
                },
                pastHistory: {
                    "trauma": {"has": null, "list": []},
                    "illness": {"has": null, "list": []},
                    "surgery": {"has": null, "list": []},
                    "transfusion": {"has": null, "list": []}
                },
                other: {
                    "all": "", "frist": "", "expose": [], "heredity": 0,
                    "disability": {"value": [], "remark": ""}, "heredityDetail": "",
                    "illnessHostory": "", "drugIrritability": {"value": [], "remark": ""},
                    "foodsIrritability": ""
                },
                environment: {"water": "", "energy": "", "toilet": "", "acorral": "", "exhaust": ""},
                lifeInfo: {"daily": "", "drink": "", "foods": "", "sleep": "", "smoke": "", "style": ""},
                healthInfo: {"BMI": "", "blood": "", "waist": "", "height": "", "weight": ""}
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(from.path.indexOf("/main/phr/detail/")>-1){
                    vm.handlePopState()
                }
            });
        },
        methods: {
            async handleDownload() {
                this.progress = 0; // 初始化进度
                this.dialogVisibleExport = true; // 显示进度条对话框
                // 模拟导出数据的过程
            },
            async exportLimitedData() {
                try {
                    this.but_loading = true
                    this.but_disabled = true
                    this.$http({
                        url: `/healthProgress/export`,
                        method: 'POST',
                        responseType: 'blob', // 重要：确保响应类型为blob
                        data: this.search,
                        headers: {},
                        onDownloadProgress:(progressEvent)=>{
                            let progressBar = Math.round( progressEvent.loaded / progressEvent.total*100);
                            //接收进度为99%的时候需要留一点前端编译的时间
                            if(progressBar >= 99){
                                this.progress = 99;
                            }else{
                                this.progress = progressBar;
                            }
                        }
                    }).then(response => {
                        this.progress = 100
                        const url = window.URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        let fileName = '档案进度.xlsx';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                            if (fileNameMatch && fileNameMatch.length === 2) {
                                fileName = decodeURIComponent(fileNameMatch[1]);
                            }
                        }
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url); // 清理
                        this.but_loading = false
                        this.but_disabled = false
                        this.dialogVisibleExport = false; // 隐藏导出进度对话框
                        this.$message.success('数据导出完成！');
                    }).catch((error) => {
                        this.but_loading = false
                        this.but_disabled = false
                        this.$message({
                            message: error,
                            type: 'error'
                        });
                    });
                } catch (error) {
                    this.but_loading = false
                    this.but_disabled = false
                    this.$message({
                        message: error,
                        type: 'error'
                    });
                }
            },
            handlePopState() {
                const savedConditions = localStorage.getItem('searchConditions');
                const black = localStorage.getItem('back');
                if (savedConditions && black) {
                    this.search = JSON.parse(savedConditions);
                }
            },
            handleRemind() {
                this.$confirm(`确认要催办基础问卷吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/questionnaire/remind`, {userIdList: this.idList})
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            handleSendQuestionnaire() {
                this.$confirm(`确认要发送基础问卷吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/questionnaire/send`, {userIdList: this.idList})
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') {
                                this.getData()
                            }
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            },
            async handleRowDangan(row) {
                try {
                    const resp = await this.$http.get(`/phr/view/${row.id}`)
                    if (resp.data) {
                        if (!resp.data.data.familyHistory) {
                            resp.data.data.familyHistory = this.familyHistory
                        }
                        if (!resp.data.data.pastHistory) {
                            resp.data.data.pastHistory = this.pastHistory
                        }
                        if (!resp.data.data.other) {
                            resp.data.data.other = this.other
                        }
                        if (!resp.data.data.environment) {
                            resp.data.data.environment = this.environment
                        }
                        if (!resp.data.data.lifeInfo) {
                            resp.data.data.lifeInfo = this.lifeInfo
                        }
                        if (!resp.data.data.healthInfo) {
                            resp.data.data.healthInfo = this.healthInfo
                        }
                        if (!resp.data.data.patientInfo) {
                            resp.data.data.patientInfo = {
                                name: row.name,
                                sex: row.sex,
                                age: row.age,
                                mobile: row.mobile,
                                idCard: row.idCard,
                                companyName: row.companyName,
                                wpName: row.wpName,
                            }
                        }
                        this.form = resp.data.data

                        this.infoVisible = true
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            handleRowWenJuan(row) {
                this.$refs.dataform.getData(row.id);
                this.dialogVisible = true
            },
            maskIdCardRegex(idCard) {
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                // 切换显示状态
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            sendSMSAct() {
                this.$confirm(`确认要发送激活短信吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.post(`/healthProgress/sendSMSAct`, {ids: this.idList})
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handleAdd() {
                this.$message({
                    message: '开发中',
                    type: 'warning'
                });
                // this.form.KHBQ = []
                // this.dialogVisible = true
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                    localStorage.removeItem('searchConditions');
                    localStorage.removeItem('back');
                }, 0);
            },
            handleRowChange(row) {
                this.$router.push({path: `/main/phr/detail/${row.id}`})
            },
            calculateBirthdayFromId(id) {
                // 确保身份证号码为字符串
                const idStr = id.toString();

                // 第二代身份证号码长度为18
                if (idStr.length !== 18) {
                    return "";
                }

                // 提取出生年月日
                const year = idStr.substring(6, 10);
                const month = idStr.substring(10, 12);
                const day = idStr.substring(12, 14);

                // 组合成生日字符串
                const birthday = `${year}-${month}-${day}`;

                // 验证日期的有效性
                if (!this.isValidDate(birthday)) {
                    return "";
                }

                return birthday;
            },

            isValidDate(dateString) {
                const date = new Date(dateString);
                return date instanceof Date && !isNaN(date);
            },

            handleSelectionChange(val) {
                var that = this
                that.idList = []
                val.forEach(function (item) {
                    var patientInfo = {
                        "name": item.name,
                        "sex": item.sex,
                        "age": item.age,
                        "mobile": item.mobile,
                        "idCard": item.idCard,
                        "companyName": item.companyName,
                        "birthday": that.calculateBirthdayFromId(item.idCard)
                    }
                    that.idList.push({id: item.id, patientInfo: patientInfo,reminder_count:item.reminder_count,healthCreateStatus:item.healthCreateStatus,
                        openId:item.openid,questionnaire_id:item.questionnaire_id, questionnaireTime:item.questionnaireTime,activeStatus:item.activeStatus,
                        questionnaireStatus:item.questionnaireStatus,})
                })
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/healthProgress`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                    localStorage.setItem('searchConditions',JSON.stringify(this.search));
                }
                this.loading = false
            }
        },
        mounted() {
            this.getUserOwn()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
