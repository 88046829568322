<template>
    <div class="page">
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="单位分组">
                    <el-select v-model="search.type" placeholder="" style="width:100%">
                        <el-option label="全部" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评估类型待定">
                    <el-select v-model="search.type" placeholder="" style="width:100%">
                        <el-option label="全部" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="search.type" placeholder="" style="width:100%">
                        <el-option label="全部" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                    <el-button type="" plain @click="getData">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading"
                          style="width: 100%;cursor: pointer" height="calc(100vh - 400px)" :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column label="问卷发送时间" prop="organization"/>
                    <el-table-column label="问卷回填时间" prop="organization"/>
                    <el-table-column label="类型" prop="organization"/>
                    <el-table-column label="状态" prop="organization"/>
                    <el-table-column label="心理评估标签" prop="organization"/>
                    <el-table-column label="单位分组" prop="organization"/>
                    <el-table-column label="操作人" prop="organization"/>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="handleShowQuestionnaire(scope.$index, scope.row)">查看详情</el-button>
                            <el-button type="text" size="small" style="color:#000;">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>

        <el-dialog title="问卷结果" :visible.sync="dialogTableVisible">
            <el-form>
                <el-form-item label="活动名称" prop="name">
                    <el-input v-model="questionnaireForm.name"></el-input>
                </el-form-item>
                <el-select v-model="questionnaireForm.region" placeholder="请选择活动区域">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select>

                <el-form-item label="活动性质" prop="type">
                    <el-checkbox-group v-model="questionnaireForm.type">
                        <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                        <el-checkbox label="地推活动" name="type"></el-checkbox>
                        <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                        <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

            </el-form>

        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: 'psychologicalAssessment',
        data() {
            return {
                loading: false,
                dialogTableVisible: false,
                search: { type: '' },
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                questionnaireForm:{
                    type:""
                }
            }
        },
        methods: {
            // 查看问卷
            handleShowQuestionnaire(index, row) {
                this.dialogTableVisible = true
                console.log(index, row)
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/doctor/huanzhe?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
        .pager {
            padding-top: 10px;
            text-align: center;
        }
    }
</style>
